.usersContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.usersTable {
  width: 100%;
  text-align: center;
}

.usersTable > tr {
  border-bottom: 1px solid lightgray;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  padding: 15px;
}

.usersTable > tr > th {
  font-weight: 700;
}

.usersIcons,
.candidatesTestIcons {
  display: flex;
  justify-content: space-evenly;
}

.usersIcon,
.candidatesTestIcon {
  cursor: pointer;
  font-size: 30px !important;
}

.usersIcon:hover,
.candidatesTestIcon:hover {
  opacity: 80%;
}

.usersContainer > button {
  display: flex;
  margin: auto;
  border: none;
  background: #ef711d;
  padding: 12px 18px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  margin: 10px auto;
}

.usersContainer > button:hover {
  background: #9fdd3a;
}

.userSingleRole {
  text-transform: uppercase;
}

.usersPhoto {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

/*        */

.usersAddPopupContainer,
.candidatesTestsAddEditPopupContainer,
.studentsTestsAddEditPopupContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.usersAddPopupWrapper,
.candidatesTestsAddEditPopupWrapper,
.studentsTestsAddEditPopupWrapper {
  background-color: rgba(255, 255, 255, 0.9);
  width: 30%;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  box-shadow: 0 0 10px lightgray;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
}

.usersAddPopupCloseBtn,
.candidatesTestsAddEditPopupCloseBtn,
.studentsTestsAddEditPopupCloseBtn {
  font-size: 25px;
  cursor: pointer;
  //float: right;
  text-align: end;
}

.usersAddPopupCloseBtn:hover,
.candidatesTestsAddEditPopupCloseBtn:hover,
.studentsTestsAddEditPopupCloseBtn:hover {
  color: #ef711d;
}

.usersAddPopupForm,
.candidatesTestsAddEditPopupForm,
.studentsTestsAddEditPopupForm {
  display: flex;
  flex-direction: column;
}

.usersAddPopupForm > label,
.candidatesTestsAddEditPopupForm > label,
.studentsTestsAddEditPopupForm > label {
  margin: 2.5px 0;
  text-align: center;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.usersAddPopupForm > label > input,
.usersAddPopupForm > label > select,
.candidatesTestsAddEditPopupForm > label > input,
.candidatesTestsAddEditPopupForm > label > select,
.candidatesTestsAddEditPopupTestAnswersLabel,
.studentsTestsAddEditPopupForm > label > select {
  padding: 10px;
  width: 100%;
  border: 1px solid lightgray;
  text-align: center;
}

.candidatesTestsAddEditPopupTestAnswersLabel,
.studentsTestsAddEditPopupTestAnswersLabel {
  cursor: pointer;
}

#usersAddPopupPhoto {
  background-color: white;
  border: 1px solid lightgray;
  margin-bottom: 20px;
  display: block;
  padding: 10px;
  outline-color: #9fdd3a !important;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.usersAddPopupBtn,
.candidatesTestsAddEditPopupSubmitBtn,
.studentsTestsAddEditPopupSubmitBtn {
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  width: 30%;
  margin: 10px auto;
}

.usersAddPopupBtn:hover,
.candidatesTestsAddEditPopupSubmitBtn:hover,
.studentsTestsAddEditPopupSubmitBtn:hover {
  background-color: #4a154d;
  color: white;
}

.usersAddPopupPhotoPreview {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin: 10px auto;
}

.usersTableAdmin{
  font-weight:bold;
}

.usersNoOtherUsersText{
text-align: center;
margin-top: 20px;
margin-bottom: 20px;
}