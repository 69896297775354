.loginContainer {
  width: 100%;
  height: 400px;
  background: #4a154d;
  box-sizing: border-box;
  padding: 35px;
}

.loginHeader {
  font-weight: 700;
  font-size: 35px;
  color: white;
  font-family: "Josefin Sans", sans-serif;
  margin-bottom: 10px;
}

#loginForm {
  height: 100%;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-items: center;
}

#loginForm input,
#loginForm textarea {
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: #8b8b8b;
}

.loginFormUsername,
.loginFormPassword {
  width: 400px;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: white;
}

.loginFormPassword {
  display: flex;
  justify-content: space-between;
}

.loginFormPassword > input {
  width: 100%;
  height: 100%;
}

#loginForm input:empty,
#loginForm textarea:empty {
  color: black;
}

.loginFormUsername:focus,
.loginFormPassword > input:focus {
  outline: none;
}

/*TO-DO (this may be how to change the input color; some are gray some are black

.loginFormUsername:-webkit-autofill,
.loginFormPassword:-webkit-autofill {
}*/

.loginBtn {
  border: none;
  background: #ef711d;
  padding: 10px;
  cursor: pointer;
  color: white;
  font-size: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  margin-top: 10px;
}

.loginPwdIcon {
  cursor: pointer;
}

// RESPONSIVE DESIGN STYLES

@media all and (max-width: 400px) {
  .loginFormUsername,
  .loginFormPassword {
    width: 300px !important;
  }
  .loginHeader {
    font-size: 30px;
  }
}

@media all and (max-width: 500px) {
  .loginFormUsername,
  .loginFormPassword {
    width: 360px;
  }
}
