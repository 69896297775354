.accessDeniedContainer {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 60px;
}

.accessDeniedContainer > h1 {
  font-size: 50px;
  font-family: "Josefin Sans", sans-serif;
}

.accessDeniedContainer > p {
  font-family: "Varela", sans-serif;
  font-size: 25px;
}
