@import url("https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Carter+One&display=swap");

.wallOfFameContainer {
  //background: url("../../images/Wood.jpeg") no-repeat;
  background-color: #4a154de6;
  object-fit: contain;
  background-size: 100%;
  background-repeat: repeat;
}

/*
.wallOfFameTextWrapper {
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  width: 40%;
  margin: auto;
}

.wallOfFameTextWrapper > p {
  color: goldenrod;
  margin-top: auto;
  font-size: 70px;
  font-weight: 700;
  font-family: "Josefin Sans", sans-serif;
}

.wallOfFameGoldenStar{
  vertical-align: middle;
  margin-right: 5px;
}
  
.wallOfFameGoldenStar > p { 
  font-family: "Josefin Sans", sans-serif;
  font-size: 50px;
  color: #d10000 !important;
  font-weight: 700;
  //position: absolute;
  //top: 60%;
  //left: 48%;
  outline: black;
}

.wallOfFameStarIcon {
  color: goldenrod;
  font-size: 150px !important;
  //position: relative;
  //text-align: center;
}
*/

.wallOfFameTextWrapper {
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: auto;
  align-items: center;
}

.wallOfFameTextWrapper > p {
  color: goldenrod;
  font-size: 70px;
  font-weight: 700;
  font-family: "Josefin Sans", sans-serif;
}

.wallOfFameGoldenStar {
  position: relative;
}

.wallOfFameStarIcon {
  color: goldenrod;
  font-size: 150px !important;
  /*position: absolute;
  top: -100px;
  left: -50px;*/
}

.wallOfFameGoldenStar > p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 40px;
  color: #d10000 !important;
  font-weight: 700;
  outline: black;
  position: absolute;
  top: 60px;
  left: 45px;
}

/****/

.wallOfFameGoldenStar {
  display: inline-flex;
  align-items: center;
}

.wallOfFameWrapper {
  padding: 20px;
  margin: auto;
  width: 90%;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}

.wallOfFameTrophyWrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  //
  position: relative;
  text-align: center;
}

.wallOfFameTrophyWrapper > img {
  margin: auto;
  width: 160px;
}

.wallOfFameTrophyWrapper > span {
  //margin: auto;
  width: 70px;
  font-size: 16px;
  //font-family: "Black Ops One", cursive;
  font-family: "Carter One", cursive;
  text-transform: capitalize;
  //
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(0, 0, 0, 0.7);
}

//RESPONSIVE DESIGN STYLES

@media all and (max-width: 500px) {
  .wallOfFameTextWrapper > p {
    font-size: 40px !important;
  }
  .wallOfFameGoldenStar > p {
    font-size: 30px;
    top: 36px;
    left: 30px;
  }
}

@media all and (max-width: 650px) {
  .wallOfFameTextWrapper > p {
    font-size: 55px;
  }
  .wallOfFameStarIcon {
    font-size: 100px !important;
  }
  .wallOfFameGoldenStar > p {
    font-size: 30px;
    top: 36px;
    left: 30px;
  }
  .wallOfFameWrapper {
    display: flex;
    flex-direction: column;
  }
  .wallOfFameTrophyWrapper > img {
    width: 230px;
  }
  .wallOfFameTrophyWrapper > span {
    width: 150px;
    font-size: 20px;
  }
}

@media all and (max-width: 900px) {
  .wallOfFameWrapper {
    grid-template-columns: auto auto auto !important;
  }
}

@media all and (max-width: 1100px) {
  .wallOfFameWrapper {
    grid-template-columns: auto auto auto auto;
  }
}

@media all and (max-width: 1400px) {
  .wallOfFameWrapper {
    width: 100%;
  }
}
