.cycleContainer {
  width: 80%;
  margin: auto;
  padding: 5vh 0;
}

.cycleWrapper {
  display: flex;
  justify-content: space-around;
}

.cycleLevel {
  clip-path: circle(120px);
  height: 250px;
  width: 250px;
  background-size: cover !important;
  display: flex;
  cursor: pointer;
}

.cycleLevel:hover {
  //opacity: 90%;
}

.cycleLevel > p {
  margin: auto;
  color: white;
  font-weight: 700;
  font-size: 25px;
  letter-spacing: 0.15em;
  stroke: solid black;
  font-family: "Varela", sans-serif;
}

.cyclesText {
  background-color: rgba(0, 0, 0, 0.5);
}

/// STYLE QUERIES

@media all and (max-width: 1300px) {
  .cycleContainer {
    width: 100%;
  }
}

@media all and (max-width: 1050px) {
  .cycleWrapper {
    flex-direction: column;
    align-items: center;
  }
  .cycleLevel {
    margin-top: 20px;
  }
}
