.applyContainer {
  width: 100%;
}

.applyTop {
  width: 100%;
  //height: 300px;
  /*background-image: url("../../images/Colleagues.jpg");
  box-shadow: inset 0 0 999999px rgba(74, 21, 77, 0.8);*/
  background: linear-gradient(to top, #4a154db3, #4a154db3),
    url("../../images/Colleagues.jpg") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
}

.applyHeader {
  color: white;
  font-family: "Lora", serif;
  font-size: 60px;
  text-align: center;
  margin: 50px auto;
}

.applyIconsSpansWrapper {
  display: flex;
  justify-content: space-evenly;
  font-family: "Poppins", sans-serif;
}

.applyIconsSpansWrapper > span {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.applyIcon,
.applyIconsSpansWrapper > span > p {
  color: #9fdd3a;
}

.applyForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #4a154d;
  padding: 20px 0;
}

/*       ************        */

#applyForm {
  width: 60%;
  //overflow: hidden;
  align-self: center;
  margin: auto;
}

.applyCol1 > input,
.applyCol2 > input {
  width: 90%;
}

.applyColRequired {
  display: flex;
  justify-content: space-between;
}

.applyColRequired > p {
  color: red;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  padding: 10px;
  text-align: center;
  float: right;
}

.applyColRequired > input,
.applyColRequired > select {
  outline: none;
  border: none;
}

.applyCol1 {
  float: left;
}

.applyCol2 {
  float: right;
  position: relative;
}

.applyColMid {
  margin: 25px auto !important;
}

.applyCol1,
.applyCol2,
.applyColMid {
  margin: 25px 10px 25px 0px;
  display: block;
  width: 400px;
  height: 60px;
  padding: 10px;
  background-color: white;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: #8b8b8b;
  outline-color: #9fdd3a !important;
}

.applyMultiSelect {
  width: 400px;
}

.applyCol1:empty,
.applyCol2:empty {
  color: black;
}

.applyFormBtn,
.applyFormBtnNotReady {
  background: #ef711d;
  color: white;
  display: flex;
  margin: auto;
  border: none;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.applyFormBtn:hover,
.applyFormBtnNotReady:hover {
  background: #9fdd3a;
}

#resume,
#position {
  margin-bottom: 50px;
}

.applyForm > span {
  display: flex;
  width: fit-content;
  margin: auto;
}

.applyForm > span > span {
  margin: 15px auto;
  display: flex;
  color: red;
  font-family: "Varela", sans-serif;
  font-size: 25px;
  font-style: italic;
}

.applyForm > span > p {
  font-family: "Varela", sans-serif;
  font-style: italic;
  color: white;
  font-size: 20px;
  margin: auto;
  padding-left: 20px;
}

/*****/

.applySubmitted {
  background-color: #4a154d;
  padding: 50px 20px;
  //width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 300px;
}

.applySubmitted > p {
  color: white;
  font-family: "Varela", sans-serif;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
}

.applySubmittedBtn {
  margin: 10px 0;
  width: 300px;
  height: 50px;
  border: none;
  background: #ef711d;
  padding: 12px 18px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.applySubmittedBtn:hover {
  border: 1px solid #ef711d;
  background-color: white;
  color: #ef711d;
}

.applySelectRequired {
  display: flex;
  justify-content: space-between;
}

.applySelectRequired > p {
  color: red;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  padding: 10px;
  text-align: center;
  float: right;
}

.applyColAttachment > label {
  width: 90%;
  cursor: pointer;
}

.applyFormImgPreviewSpan {
  position: relative;
}

.applyFormImgPreviewLength {
}

.applyFormImgPreview {
  position: relative;
  z-index: 10;
  float: right;
  width: 25px;
  height: 25px;
  object-fit: contain;
  border: 0.5px solid lightgray;
}

.applyFormImgPreview:hover {
  transform: scale(20);
  border: none;
  z-index: 999 !important;
}

.applyRadioBtns {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}

.applyRadioBtns > span > label {
  padding-left: 10px;
}

.applyCol1 > select {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

.applyCol {
  display: block;
  width: 400px;
  height: 60px;
  padding: 10px;
  background-color: white;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: #8b8b8b;
  outline-color: #9fdd3a !important;
  margin: 25px auto;
}

.applyApplicationLetter {
  width: 100%;
  min-height: 400px;
  height: 100%;
  border: none;
  padding: 15px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.applyButtonGoBack {
  //  border: 1px solid red;
  width: 100px;
}

.applyGoBackArrow {
  color: #ef711d;
  font-size: 60px !important;
  position: relative;
  left: 150px;
  font-size: 24px;
  cursor: pointer;
}

// RESPONSIVE DESIGN STYLES

@media all and (max-width: 450px) {
  .applyCol1,
  .applyCol2,
  .applyColMid,
  .applyMultiSelect {
    width: 320px;
    height: 60px;
  }
  .applyHeader {
    width: 100% !important;
  }
  .applyIconsSpansWrapper > span > p {
    text-align: center;
  }
}

@media all and (max-width: 500px) {
  .applyRadioBtns {
    justify-content: space-between !important;
  }
  .applyIconsSpansWrapper > span > p {
    font-size: 15px;
  }
}

@media all and (max-width: 550px) {
  .applyHeader {
    width: 80%;
    margin: 10px auto;
  }
}

@media all and (max-width: 600px) {
  .applyForm > span > p {
    width: 80%;
    text-align: center;
    padding-left: 0px;
    padding-right: 20px;
  }
}

@media all and (max-width: 700px) {
  .applyForm > span > p {
    font-size: 18px;
  }
}

@media all and (max-width: 750px) {
  .applyHeader {
    font-size: 35px !important;
  }
}

@media all and (max-width: 800px) {
  .applyGoBackArrow {
    margin-bottom: 50px;
    position: absolute;
    left: 50px;
  }
  .applyFormInnerdiv {
    margin-top: 60px;
  }
}

@media all and (max-width: 900px) {
  .applyIconsSpansWrapper {
    display: grid;
    grid-template-columns: auto auto;
  }
  .applyIcon {
    font-size: 60px !important;
  }
}

@media all and (max-width: 1000px) {
  .applyHeader {
    font-size: 45px;
  }
}

@media all and (max-width: 1400px) {
  .applyFormInnerdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .applyRadioBtns {
    justify-content: space-evenly;
    width: 100%;
  }
  .applyRadioBtns > span > label {
    padding-left: 10px;
  }
}
