.accreditationsContainer {
  display: flex;
  justify-content: space-around;
  width: 80%;
  align-self: center;
  margin: auto;
  padding: 100px 0;
}

/// STYLE QUERIES

@media all and (max-width: 650px) {
  .accreditationsContainer {
    flex-direction: column;
    align-items: center;
  }

  .accreditationsContainer > img {
    margin-top: 50px;
    width: 200px;
    height: 200px;
  }
}

@media all and (max-width: 850px) {
  .accreditationsContainer {
    width: 100%;
  }
}
