.virtualTourContainer {
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
}

.virtualTourContainer > h1 {
  margin: 30px 0;
  color: #ef711d;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

/// STYLE QUERIES

@media all and (max-width: 450px) {
  .virtualTourContainer > h1 {
    font-size: 30px;
  }
}

@media all and (max-width: 900px) {
  .virtualTourContainer > iframe {
    width: 100% !important;
    height: auto;
    aspect-ratio: 853 / 480;
  }
}
