.clubContainer {
}

.cycleGoBackArrow {
  margin-left: 30px;
  margin-top: 30px;
}

.clubPhotos {
  height: 350px;
  width: 60%;
  background: cover no-repeat !important;
  display: flex;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 15px auto;
}

.clubPhotos:hover {
  opacity: 90%;
}

.clubPhotos > p {
  margin: auto;
  color: white;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.15em;
  stroke: solid black;
  font-family: "Varela", sans-serif;
}

.clubSocialMediaIcons,
.virtualExchangeSocialMediaIcons {
  display: flex;
  justify-content: space-around;
  width: 30%;
  margin: 20px auto;
}

.clubSocialMediaIcon:hover,
.virtualExchangeSocialMediaIcon:hover {
  transform: scale(1.4);
}

.clubIGIcon {
  color: #e66465 !important;
}

.clubTwitterIcon {
  color: #00acee !important;
}

.clubFBIcon {
  color: #3b5998 !important;
}

.clubSocialMediaIconSingle {
  width: fit-content;
  margin: 20px auto;
}

.clubYTIcon,
.virtualExchangeYTIcon {
  color: red !important;
}

.goBackArrowAndClubContainerWrapper {
  display: flex;
}

// RESPONSIVE DESIGN STYLES

@media all and (max-width: 650px) {
  .clubSocialMediaIcons,
  .virtualExchangeSocialMediaIcons {
    width: 100% !important;
  }
  .goBackArrowAndClubContainerWrapper {
    flex-direction: column;
  }
  .clubContainer > h1 {
    margin: 0px;
    padding: 20px 0px;
  }
}

@media all and (max-width: 1000px) {
  .clubSocialMediaIcons,
  .virtualExchangeSocialMediaIcons {
    width: 50%;
  }
}
