.footerContainer {
  width: 100%;
  //height: 400px; //was fit-content;
  background-color: #4a154d;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  bottom: 0 !important;
  left: 0 !important;
}

.footerTop {
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;
}

.footerTopHeaderLocationDiv {
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: flex-start;
}

.footerTopAddress,
.footerTopPhone,
.footerTopEmail {
  padding-left: 10px;
}

.footerTop1 {
  flex: 4;
  padding: 10px;
}

.footerTop2,
.footerTop3,
.footerTop4,
.footerTop5 {
  flex: 3;
}

.footerTop6 {
  flex: 2;
}

.footerTop4IconsAndQR {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 50%;
  width: fit-content;
}

.footerTopHeader {
  color: white;
  list-style-type: none;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px;
  padding: 8px;
}

.footerTopHeader > li,
.footerInLine {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}

.footerTopHeaderLocationText {
  height: 40px;
}

.footerTopHeader > li {
  text-indent: 20px;
  font-weight: 400;
  cursor: pointer;
  line-height: 2.8vh;
}

.footerTopHeader > li:hover {
  opacity: 80%;
}

.footerInLine {
  display: flex;
  //color: rgba(255, 255, 255, 0.8);
}

.footerTopPhone,
.footerTopEmail {
  width: 100%;
  padding-top: 8px;
}

.footerSocialIcons {
  display: flex;
  justify-content: space-around;
  color: white;
  cursor: pointer;
}

.footerSocialIcon {
  font-size: 4vh;
}

.footerQR {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.footerMap {
  width: 260px;
  height: 160px;
}

.footerBottom {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 14px;
}

#developedBy {
  color: transparent;
  text-align: center;
}

/// STYLE QUERIES

@media all and (max-width: 650px) {
  .footerTopAddress {
    text-align: center;
  }
  .footerTopHeaderLocationText {
    width: 90%;
  }
  .footerTop {
    flex-direction: column;
    align-items: center;
  }
  .footerTop1,
  .footerTop2,
  .footerTop3,
  .footerTop4,
  .footerTop5,
  .footerTop6 {
    width: 100%;
    text-align: center;
  }
  .footerTop4IconsAndQR {
    margin: auto;
  }
  .footerTopHeader {
    font-size: 18px !important;
  }
  .footerTopHeader > li {
    line-height: 30px !important;
  }
  .footerTopHeader > li,
  .footerInLine {
    font-size: 15px !important;
  }
  .footerTopHeaderLocationDiv {
    margin-bottom: 20px;
    align-items: center;
  }
  .footerTopHeaderLocationText {
    text-align: left;
    margin-bottom: 20px;
  }
  .footerQR {
    height: 150px !important;
  }
  .footerSocialIcon {
    font-size: 5vh !important;
  }
}

@media all and (max-width: 900px) {
  .footerQR {
    width: 90%;
    height: 90px;
  }
  .footerMap {
    width: 200px;
    height: 120px;
  }
}

@media all and (max-width: 1000px) {
  .footerTop4IconsAndQR {
  }
  .footerTopHeader {
  }
  .footerTopHeader > li,
  .footerInLine {
  }
  .footerTopHeaderLocationText {
  }
  .footerTopHeader > li {
    text-indent: 10px;
    line-height: 3vh;
  }
  .footerSocialIcon {
    font-size: 3vh;
  }
  .footerQR {
  }
  .footerMap {
  }
  .footerBottom {
  }
}

@media all and (min-width: 1000px) {
  .footerSocialIcon {
    font-size: 4vh;
  }
}

@media all and (max-width: 1080px) {
  //Change the Carousel
  //The photo is too low
  .footerTopHeader {
    font-size: 13px;
  }
  .footerTopHeader > li,
  .footerInLine {
    font-size: 10px;
  }
  .footerTopHeader > li {
    text-indent: 0px;
    line-height: 2vh;
  }
  .footerSocialIcon {
    font-size: 2.5vh;
  }
}
