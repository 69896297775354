.letterContainer {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: url("https://i.pinimg.com/originals/4c/65/f9/4c65f913798dcd566fb929d0973a37e6.jpg");
}

.letterContainer > h1 {
  margin: 40px 0;
  color: #ef711d;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.letterContainer > img {
  width: 500px;
  height: 350px;
  object-fit: contain;
  margin: auto;
}

.letterContainer > p {
  width: 60%;
  margin: 20px auto;
  padding: 10px;
  line-height: 50px;
  font-family: "Lora", serif;
  font-size: 25px;
  word-spacing: 5px;
  text-indent: 25px;
}

.letterContainer > p:first-letter {
  font-weight: 700;
  font-size: 30px;
}

.letterContainer > span {
  width: 60%;
  margin: auto;
  line-height: 50px;
  font-family: "WindSong", cursive;
  font-weight: bold;
  font-size: 25px;
  text-indent: 25px;
}

/// STYLE QUERIES

@media all and (max-width: 770px) {
  .letterContainer > h1 {
    font-size: 32px;
  }
  .letterContainer > img {
    width: 100%;
  }
  .letterContainer > p {
    width: 80%;
  }
}
