.currentHiringNeedsContainer {
  display: flex;
  flex-direction: column;
}

.currentHiringNeedsAddBtn {
  display: flex;
  margin: 25px auto;
  border: none;
  background: #ef711d;
  padding: 12px 18px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.currentHiringNeedsAddBtn:hover {
  background: #9fdd3a;
}

.currentHiringNeedsWrapper {
  margin: auto;
  width: 70%;
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.currentHiringNeed {
  margin: 10px auto;
  width: 200px;
  height: 200px;
  object-fit: contain;
  border: 1px solid lightgray;
  border-radius: 15px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 15px;
}

.currentHiringNeedUrgt {
  border: 2px solid #ef711d !important;
}

.currentHiringNeedsColorCode {
  display: flex;
  width: 65%;
  margin: 18px auto;
  font-style: italic;
}

.currentHiringNeedsColorCode > span {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  border: 2px solid #ef711d;
  margin-right: 10px;
}

.currentHiringNeedDeleteIcon {
  cursor: pointer;
}

.currentHiringNeedDeleteIcon:hover {
  opacity: 80%;
}

/*   Create New Need     */

.createNewNeedContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}
 
.createNewNeedWrapper {
  background-color: rgba(255, 255, 255, 1);
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px lightgray;
  position: fixed;
  top: 20%;
  left: 25%;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.createNewNeedWrapper > p {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}

.createNewNeedForm {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
}

.createNewNeedForm > span {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.createNewNeedForm > span > label {
  width: 40%;
}

.createNewNeedForm > span > select, 
.createNewNeedForm > span > input,
.currentHiringNeedsMultiSelectSpan {
  width: 60%;
}

.createNewNeedForm > span > input {
  padding-left: 10px; 
  padding-right: 10px;
}

.createNewNeedForm > span > select, 
.createNewNeedForm > span > input,
.currentHiringNeedsMultiSelect{
  border: 1px solid lightgray;  
}

.indicatorsContainer {
  order: 2;
  margin-left: 5px;
}

.createNewNeedBtns {
  margin: auto;
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.createNewNeedBtns > button {
  border: none;
  padding: 15px;
  cursor: pointer;
  font-size: 20px;
  }

.createNewNeedBtns > button:hover {
  background-color: #4a154d;
  color: white;
}
