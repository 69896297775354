.candidatesTestsAddEditPopupTestPreview,
.candidatesTestsAddEditPopupAnswersPreview {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin: auto;
}

.studentsTestsAddEditPopupTestPreview {
  width: 50;
  height: 50px;
  object-fit: contain;
  margin: auto;
}

.candidatesTestsTableVisibilityIcon {
  cursor: pointer;
}

.candidatesTestsTableVisibilityIcon:hover {
  opacity: 70%;
}
