.suppliesListsUL {
  min-width: fit-content;
  width: 100%;
  list-style-type: none;
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
}

.suppliesListsUL > span {
  display: flex;
  margin-bottom: 10px;
  width: 40%;
  margin: auto;
}

.suppliesListsUL > span > li {
  cursor: pointer;
}

.suppliesListsUL > span > li:hover {
  opacity: 90%;
}

.suppliesListsCycle {
  margin-bottom: 10px;
  font-family: "Josefin Sans", sans-serif;
  font-size: 25px;
  color: #9fdd3a;
}

// RESPONSIVE DESIGN STYLES
@media all and (max-width: 800px) {
  .suppliesListsUL > span {
    width: 60%;
  }
}

@media all and (max-width: 600px) {
  .suppliesListsUL > span {
    width: 100%;
  }
}

@media all and (max-width: 400px) {
  .suppliesListsUL {
    font-size: 18px;
  }
  .suppliesListsCycle {
    font-size: 20px;
  }
}
