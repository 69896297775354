.tigersNightsPhotoSpan{
        margin: 20px auto;
        height: 300px;
        width: 50%;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        display: flex;
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.tigersNightsPhotoSpan > p {
    margin: auto;
    color: white;
    font-weight: 700;
    font-size: 28px;
    letter-spacing: 0.15em;
    stroke: solid black;
    font-family: "Varela", sans-serif;
}

.tigersNightsWrapper{
margin-bottom: 50px;}