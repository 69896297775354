.servicesContainer {
  grid-template-columns: auto auto;
  width: 80%;
  justify-content: space-evenly;
  display: grid;
  margin: auto;
  padding: 20px;
  height: 1000px;
}

.servicesContainer > div {
  display: flex;
  height: 100%;
  width: fit-content;
  flex-direction: column;
  justify-content: space-evenly;
}

.servicesSpan {
  height: 250px;
  width: 450px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  display: flex;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.servicesSpan:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
}

.servicesSpan:hover {
  opacity: 90%;
}

.servicesSpan > p {
  margin: auto;
  color: white;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0.15em;
  stroke: solid black;
  font-family: "Varela", sans-serif;
}

/// STYLE QUERIES

@media all and (max-width: 500px) {
  .servicesSpan {
    height: 200px;
    width: 340px;
  }
}

@media all and (max-width: 900px) {
  .servicesContainer {
    grid-template-columns: auto;
  }
  .servicesSpan {
    margin-top: 30px;
  }
}

@media all and (max-width: 1000px) and (min-width: 901px) {
  .servicesSpan {
    height: 250px;
    width: 400px;
  }
}

@media all and (max-width: 1200px) {
  .servicesContainer {
    width: 100%;
  }
}
