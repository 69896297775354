.userContainer {
width: 100%;
  //height: 250px;
  background: #4a154d;
  box-sizing: border-box;
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.userViewUsersBtn,
.userHRBtn,
.userManageCalendarBtn,
.userManagePopupBtn,
.userManageStudentTestsBtn {
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: white;
  border: none;
  width: 400px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  cursor: pointer;
}

.userViewUsersBtn:hover,
.userHRBtn:hover,
.userManageCalendarBtn:hover,
.userManagePopupBtn:hover {
  background-color: lightgray;
}

.userHRBtn:focus {
  outline-color: #9fdd3a;
}

.userUpcomingInterviewsWrapper {
  border: 1px solid lightgray;
  width: 80%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
}

.userUpcomingInterviewsTabContext {
}

.userUpcomingInterviewsTabList {
  color: white !important;
}

.userUpcomingInterviewsTableHeaders {
  font-family: "Varela", sans-serif;
  border-bottom: 1px solid rgba(211, 211, 211, 0.5);
}

.userUpcomingInterviewsTableTDs {
  font-family: "Poppins", sans-serif;
}

.userUpcomingInterviewsTable {
  width: 100%;
  font-family: "Varela", sans-serif;
}

.userUpcomingInterviewsTableBtn {
  cursor: pointer;
  color: white;
  border: 1px solid white;
  background-color: transparent;
  font-size: 2vh;
  padding: 1vh;
  margin-top: 20px;
}

.userUpcomingInterviewsTableBtn:hover {
  background-color: white;
  color: black;
}

.userZeroUpcomingInterviewsText {
  color: white;
  font-size: 18px;
  margin: 15px auto;
}
