.carouselContainer {
  position: relative;
  height: 60vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4vh;
  margin-bottom: 4vh;
}

.carouselImg {
  max-width: 100%;
  width: 800px;
  height: 440px;
  border-radius: 10px;
  object-fit: contain;
  background-repeat: no-repeat;
}

.carouselRightArrow {
  position: absolute;
  top: 50%;
  right: 100px;
  font-size: 3rem !important;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.carouselLeftArrow {
  position: absolute;
  top: 50%;
  left: 100px;
  font-size: 3rem !important;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.carouselSlide {
  opacity: 0;
  transition-duration: 1s ease;
}

.carouselSlide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

//// STYLE QUERIES
@media all and (max-width: 500px) {
  .carouselContainer {
    margin-top: 0vh;
    margin-bottom: 0vh;
  }
}

@media all and (max-width: 768px) {
  //TODO: Make the space between the carousel image and the navbar and the space between the carousel image and the htisBythenumbers smaller
}

@media all and (max-width: 1000px) {
  .carouselRightArrow,
  .carouselLeftArrow {
    color: #ef711d;
    background-color: white;
    border-radius: 50px;
    padding: 5px;
    z-index: 1;
  }
}

@media all and (max-width: 1080px) {
  .carouselLeftArrow {
    left: 25px;
  }
  .carouselRightArrow {
    right: 25px;
  }
}

@media all and (max-width: 1100px) {
  .carouselContainer {
    height: fit-content;
  }
}

@media all and (min-width: 1500px) {
  .carouselContainer {
    min-height: 60vh;
  }
  .carouselImg {
    //min-width: 1000px ;
  }
}

@media all and (min-width: 1900px) {
  .carouselImg {
    width: 800px;
    min-width: 1000px;
    //height: 800px;
  }
}

@media all and (min-width: 1920px) {
  .carouselContainer {
    min-height: 80vh;
  }
  .carouselImg {
    //min-width: 1200px ;
  }
}
