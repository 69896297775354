* {
  margin: 0;
  padding: 0;
}

.ringloader {
  color: #4a154d;
  size: 50px;
}

.links,
.linksHover {
  text-decoration: none !important;
  color: inherit !important;
}

/* HOVER ON LINK ANIMATION  */

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hover-animation-grape {
  color: #4a154d;
}

.hover-animation-grape::after {
  background-color: #4a154d;
}

.hover-animation-orange{
  color:  #ef711d;

}

.hover-animation-orange::after {
  background-color:  #ef711d;
}

.hover-animation-green{
  color:  #9fdd3a;

}

.hover-animation-green::after {
  background-color:  #9fdd3a;
}

.hover-animation-white {
  color: white;
}

.hover-animation-white::after {
  background-color: white;
}

.hover-animation-gray {
  color:#808080;
}

.hover-animation-gray::after {
  background-color:#808080;
}

/**/

.goBackSpanBtn {
  color: white !important;
  width: 100%;
}

.goBackSpanBtnDark {
  //color: black !important;
  width: 100%;
}

.clearIconDeleteField {
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.clearIconDeleteField:hover {
  opacity: 80%;
}

.fbLoginBtn {
  background: #3a5a97;
  color: #fff;

}

.fbLogoutBtn {
  color: #3a5a97;
background: #fff;
border: 1px solid #3a5a97;
}

.fbLoginBtn,
.fbLogoutBtn {
  font-family: Lucida Grande, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 18px;
  padding: 15px;
  text-shadow: 0 -1px 0 rgba(0, 0, 20, 0.4);
  line-height: 1;
  border: none;
  cursor: pointer;
}

.fbLoginBtn:hover,
.fbLogoutBtn:hover {
  opacity: 95%;
}

button:active, 
button:focus, 
input:active, 
input:focus, 
select:focus, 
select:active {
  outline: none !important;
}

//Don't change input background color on autofill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: inherit;
  background-color: transparent;
}