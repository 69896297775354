.projectContainer,
.clubContainer,
.webinarsContainer,
.radioContainer,
.publicSpeakingContainer,
.virtualExchangeContainer,
.MUNContainer,
.sportsContainer,
.tripsContainer,
.orientationContainer,
.rulesContainer,
.rulesContainer,
.scholarshipsContainer,
.tigersNightsContainer {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.projectContainer > h1,
.clubContainer > h1,
.webinarsContainer > h1,
.radioContainer > h1,
.publicSpeakingContainer > h1,
.virtualExchangeContainer > h1,
.MUNContainer > h1,
.sportsContainer > h1,
.tripsContainer > h1,
.orientationContainer > h1,
.rulesContainer > h1,
.scholarshipsContainer > h1,
.scholarshipsContainer > h1,
.tigersNightsContainer > h1 {
  margin: 40px;
  color: #ef711d;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.projectContainer > h2 {
  text-transform: capitalize;
  margin-bottom: 40px;
  color: #4a154d;
  text-align: center;
  font-family: "Varela Round", sans-serif;
}

.projectHighlight {
  text-decoration: underline 10px rgba(159, 221, 58, 0.3) !important;
  //text-underline-offset: 0px !important;
}

.projectContainer > span > ol {
  list-style-position: inside;
}

.projectContainer > span > ol > li {
  margin-left: 1.5em;
}

.projectContainer > span,
.clubContainer > p,
.webinarsContainer > p,
.radioContainer > p,
.publicSpeakingContainer > p,
.virtualExchangeContainer > p,
.MUNContainer > p,
.sportsContainer > p,
.tripsContainer > p,
.orientationContainer > p,
.rulesContainer > p,
.scholarshipsContainer > p,
.tigersNightsContainer > p {
  width: 70%;
  margin: auto;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  line-height: 50px;
  font-size: 20px;
  word-spacing: 5px;
  text-indent: 50px;
}

.projectContainer > span:first-letter,
.clubContainer > p:first-letter,
.webinarsContainer > p:first-letter,
.radioContainer > p:first-letter,
.publicSpeakingContainer > p:first-letter,
.virtualExchangeContainer > p:first-letter,
.MUNContainer > p:first-letter,
.sportsContainer > p:first-letter,
.tripsContainer > p:first-letter,
.orientationContainer > p:first-letter,
.rulesContainer > p:first-letter,
.scholarshipsContainer > p:first-letter,
.tigersNightsContainer > p::first-letter {
  font-weight: 700;
  font-size: 30px;
}

.rulesText > ol {
  margin-left: 50px;
}

/// STYLE QUERIES

@media all and (max-width: 770px) {
  .projectContainer > h1,
  .clubContainer > h1,
  .webinarsContainer > h1,
  .radioContainer > h1,
  .publicSpeakingContainer > h1,
  .virtualExchangeContainer > h1,
  .MUNContainer > h1,
  .sportsContainer > h1,
  .tripsContainer > h1,
  .orientationContainer > h1,
  .rulesContainer > h1,
  .scholarshipsContainer > h1,
  .scholarshipsContainer > h1,
  .tigersNightsContainer > h1 {
    font-size: 30px;
  }
  .projectContainer > span,
  .clubContainer > p,
  .webinarsContainer > p,
  .radioContainer > p,
  .publicSpeakingContainer > p,
  .virtualExchangeContainer > p,
  .MUNContainer > p,
  .sportsContainer > p,
  .tripsContainer > p,
  .orientationContainer > p,
  .rulesContainer > p,
  .scholarshipsContainer > p,
  .tigersNightsContainer > p {
    width: 100%;
  }
  .projectContainer > h2 {
    font-size: 26px;
  }
}
