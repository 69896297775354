.testimonial {
  // height: 400px; //had to set this manually because it kept readjusting the height based on its content
  position: relative;
  margin-top: 4vh;
}

.testimonial:after {
  position: absolute;
  top: -0 !important;
  left: 0;
  content: " ";
  width: 100%;
  height: 100px;
  float: left;
  z-index: 99;
}

.testimonialHeader {
  font-family: "Varela Round", sans-serif;
  text-align: center;
}

#testimonial4 {
}

#testimonial4 .carousel-inner:hover {
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

#testimonial4 .carousel-inner:active {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

#testimonial4 .carousel-inner .item {
  overflow: hidden;
}

.testimonial4_indicators .carousel-indicators {
  left: 0;
  margin: 0;
  width: 100%;
  font-size: 0;
  height: 20px;
  bottom: 15px;
  padding: 0 5px;
  cursor: e-resize;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.testimonial4_indicators .carousel-indicators li {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  text-indent: 0;
  margin: 2px 3px;
  cursor: pointer;
  display: inline-block;
  background: #000;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.testimonial4_indicators .carousel-indicators .active {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  margin: 2px 3px;
  //background-color: #9dd3af;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.testimonial4_indicators .carousel-indicators::-webkit-scrollbar {
  height: 3px;
}

.testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb {
  background: #eeeeee;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.testimonial4_control_button .carousel-control {
  top: 175px;
  opacity: 1;
  width: 40px;
  bottom: auto;
  height: 40px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 700;
  overflow: hidden;
  line-height: 38px;
  text-shadow: none;
  text-align: center;
  position: absolute;
  background: transparent;
  border: 2px solid #ffffff;
  text-transform: uppercase;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
  transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
}

.testimonial4_control_button .carousel-control.left {
  left: 7%;
  top: 50%;
  right: auto;
}

.testimonial4_control_button .carousel-control.right {
  right: 7%;
  top: 50%;
  left: auto;
}

.testimonial4_control_button .carousel-control.left:hover,
.testimonial4_control_button .carousel-control.right:hover {
  color: #000;
  background: black;
  border: 2px solid black;
}

.testimonial4_header {
  top: 0;
  left: 0;
  bottom: 0;
  width: 550px;
  display: block;
  margin: 30px auto;
  text-align: center;
  position: relative;
}

.testimonial4_header h4 {
  //color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.testimonial4_slide {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70%;
  margin: auto;
  padding: 20px;
  position: relative;
  text-align: center;
}

.testimonial4_slide p {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  line-height: 1.8;
  margin: 40px 0 20px 0;
}

.testimonial4_slide h4 {
  font-family: "Poppins";
  font-weight: 700;
  line-height: 38px;
  font-size: 22px;
}

.testimonial .carousel {
  padding-bottom: 50px;
}

.testimonial .carousel-control-next-icon,
.testimonial .carousel-control-prev-icon {
  width: 35px;
  height: 35px;
  filter: invert(100%);
}

////// STYLE QUERIES

@media all and (max-width: 600px) {
  .testimonialHeader {
    font-size: 30px;
  }
}

@media all and (max-width: 350px) {
  .testimonialHeader {
    font-size: 25px;
  }
}
