.editPopupContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.editPopupWrapper {
  background-color: rgba(255, 255, 255, 0.9);
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px lightgray;
  position: fixed;
  top: 20%;
  left: 25%;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.editPopupWrapper > p {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.editPopupForm {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
}

.editPopupForm > span {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.editPopupBtns {
  margin: 20px auto;
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.editPopupBtns > button {
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  width: 30%;
}

.editPopupBtns > button:hover {
  background-color: #4a154d;
  color: white;
}

.editPopupFormSpan > label, .editPopupFormPasswordWrapper {
  width: 40%;
}

.editPopupFormSpan > input, .editPopupFormSpan > select{
  width: 60%;
}

.editPopupFormImgLabel { 
  text-align: center;
  margin: auto;
  border: 1px solid #8b8b8b;
  display: block;
  width: 400px;
  height: 60px;
  padding: 10px;
  background-color: white;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: #8b8b8b;
  outline-color: #9fdd3a !important;
  cursor: pointer;
}

.editPopupFormImg {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.editPopupFormImg:hover {
  scale: 10;
}

.editPopupFormInline {
  display: flex;
}


.editPopupFormPasswordWrapper{
  width: 35%; 
 display: flex;
 justify-content: space-between;
}

.editPopupFormPasswordShowHideBtn{
  cursor: pointer;
}

.usersEditPopupSelect, .usersEditPopupInput{
  width: 400px;
  padding: 10px;
  border: 1px solid lightgray;
}

.usersEditPopupSelect, .usersEditPopupInput{
  outline: none !important;
}

.usersEditPopupSelect:focus, .usersEditPopupInput:focus{
  border: 1px solid #ef711d;
}