.radioContainer,
.webinarsContainer,
.publicSpeakingContainer,
.MUNContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.radioSocialMediaIcons,
.publicSpeakingSocialMediaIcons,
.virtualExchangeSocialMediaIcons,
.MUNSocialMediaIcons,
.tripsSocialMediaIcons {
  width: 20%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.radioSocialMediaIcon:hover,
.publicSpeakingSocialMediaIcon:hover,
.virtualExchangeSocialMediaIcon:hover,
.MUNSocialMediaIcon:hover,
.tripsSocialMediaIcon:hover {
  transform: scale(1.4);
}

.radioYTIcon,
.publicSpeakingYTIcon,
.virtualExchangeYTIcon,
.MUNYTIcon,
.tripsYTIcon {
  color: red !important;
}
