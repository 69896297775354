.studentTestsContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.studentTestsWrapper {
  width: 100%;
}

.studentTestsTableVisibilityIcon,
.studentTestsTableActionIcon {
  font-size: 30px !important;
  margin: auto 10px;
  cursor: pointer;
}

.studentTestsTableVisibilityIcon:hover,
.studentTestsTableActionIcon:hover {
  opacity: 80%;
}

.studentsTestsAddEditPopupForm > label > select {
  text-align: center;
}

.studentsTestsAddEditPopupTestIcon {
  float: right;
}

.studentsTestsAddEditPopupTestAnswersLabelInline {
  display: flex;
  margin-top: 10px;
}

.studentsTestsAddEditPopupTestAnswersLabel {
  padding: 10px;
  width: 100%;
  border: 1px solid lightgray;
  text-align: center;
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.clearIconDeleteField {
}
