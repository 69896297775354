.suppliesListContainer {
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
}

.suppliesListTextsWrapper {
  margin: 30px 0;
}

.suppliesListTextsWrapper > h1,
.suppliesListTextsWrapper > h2 {
  color: #ef711d;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.suppliesListTextsWrapper > h2 {
  font-style: italic;
}

.suppliesListShowSingle {
  display: flex;
  flex-direction: column;
}

.suppliesListImg {
  margin: auto auto 30px auto;
  width: 80%;
}

.suppliesListShowSingleHeader {
  padding: 10px;
  text-align: center;
  color: #ef711d;
  font-family: "Josefin Sans", sans-serif;
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.suppliesListUL {
  list-style-type: none;
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
}

.suppliesListUL > span {
  display: flex;
  margin-bottom: 10px;
}

.suppliesListULIcon {
  font-size: 25px !important;
  margin-right: 20px;
}

.suppliesListUL > span > li {
  cursor: pointer;
}

.suppliesListUL > span > li:hover {
  opacity: 90%;
}

.suppliesListRedirectContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  font-family: "Poppins", sans-serif;
}

.suppliesListRedirectContainer > p {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 3px;
}

.suppliesListRedirectContainer > div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.suppliesListRedirectBtn {
  font-size: 20px;
  padding: 10px;
  color: white;
  margin-left: 20px;
  margin-right: 20px;
}

.suppliesListRedirectBtn:hover {
  opacity: 90%;
}

// RESPONSIVE DESIGN STYLES
@media all and (max-width: 405px) {
  .suppliesListTextsWrapper {
    margin: 0;
  }
  .suppliesListRedirectContainer > p {
    margin-bottom: 40px;
  }
  .suppliesListRedirectContainer > div {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media all and (max-width: 480px) {
  .suppliesListRedirectBtn {
    font-size: 18px;
  }
}

@media all and (max-width: 650px) {
  .suppliesListShowSingleHeader {
    font-size: 25px;
    margin-bottom: 0px;
  }
}

@media all and (max-width: 850px) {
  .suppliesListRedirectContainer {
    width: 100%;
  }
  .suppliesListRedirectContainer > div {
    display: grid;
    grid-template-columns: auto auto;
    height: 150px;
    justify-content: space-evenly;
    margin-top: 20px;
    grid-gap: 30px;
  }
  .suppliesListRedirectBtn {
    margin-left: 0px;
    margin-right: 0px;
  }
}
