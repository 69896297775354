.priceListContainer {
  padding: 20px;
}

.eLearningWrapper {
  width: 80%;
  margin: auto;
  padding: 50px;
}

.eLearningWrapper > h1 {
  font-size: 40px;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
  color: #ef711d;
}

.eLearningWrapper > p {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}

.eLearningFilter {
  margin: 20px auto;
  display: flex;
  justify-content: space-evenly;
  width: 50%;
}

.eLearningFilter > select {
  cursor: pointer;
}

.eLearningFilter > select:focus {
  outline: none;
}

.eLearningResources {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}

.eLearningResource {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.eLearningResource > img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.eLearningResourceDesc {
  display: none;
}

.eLearningResource:hover .eLearningResourceDesc {
  display: block;
}

//RESPONSIVE DESIGN STYLES

@media all and (max-width: 500px) {
  .eLearningWrapper > h1 {
    font-size: 25px !important;
  }
  .eLearningWrapper > p {
    font-size: 15px !important;
  }
}

@media all and (max-width: 600px) {
  .eLearningWrapper > h1 {
    font-size: 30px;
  }
  .eLearningWrapper > p {
    font-size: 18px;
  }
}

@media all and (max-width: 900px) {
  .eLearningWrapper {
    width: 100%;
  }
}
