@import url("https://fonts.googleapis.com/css2?family=Cookie&family=Permanent+Marker&family=Satisfy&display=swap");

.navbarContainer {
  width: 100%;
  background-color: #dbd0db;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.navbarMiddle {
  height: 200px;
  display: flex;
  align-items: center;
  padding-top: 15px;
}

.navbarMenu {
  background-color: #4a154d;
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
  align-items: center !important;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  height: 50px;
}

.navbarLogoLink {
  flex: 3;
  height: fit-content;
}

.navbarLogo {
  width: 160px;
  height: 120px; //was 164px;
  object-fit: contain;
  cursor: pointer;
  flex: 3;
  display: flex;
  margin: auto;
}

.navbarHeader {
  color: #4a154d;
  text-align: center;
  margin: auto;
  line-height: 25px; //was 45px;
}

.navbarP1 {
  font-family: "Poppins";
  font-weight: 700;
  //font-size: 40px; //was 45px;
  letter-spacing: 0.3em;
}

.navbarP2 {
  font-family: "Poppins";
  font-weight: 600;
  //font-size: 33px; //was 37px;
}

.navbarP3 {
  font-family: "Poppins";
  font-weight: 400;
  //font-size: 37px;
  letter-spacing: 0.01em;
}

.navbarP4 {
  font-family: "WindSong";
  font-weight: 500;
  //font-size: 40px; //was 45px;
}

.navbarEmptySpan {
  flex: 3;
}

.navbarMenu > li,
.narbarLink {
  color: white;
  cursor: pointer;
}

.navbarMenuActive {
  color: white;
  cursor: pointer;
}

.navbarHTISInitial {
  font-family: "Permanent Marker", cursive;
  //font-size: 42px;
  text-shadow: 1px 2px 3px white;
}

/**/

.navbarLinks {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  min-width: 220px;
  align-items: center;
}

.navbarQuickLinks {
  position: relative;
  display: inline-block;
}

.navbarQuickLinks:hover .navbarQuickLinksDropdown {
  display: block;
}

.navbarQuickLinksDropdown {
  list-style: none;
  display: none;
  position: absolute;
  background-color: #4a154d;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style-type: none;
}

.navbarQuickLinksDropdownBtn {
  cursor: pointer;
  margin-right: 10px;
}

.navbarQuickLinksDropdown > li:hover {
  background-color: #563758;
}

.navbarQuickLinksDropdown li a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.narvbarLinksTranslateSpan {
  cursor: pointer;
  color: white;
  border: 1px solid white;
  background-color: transparent;
  font-size: 2vh;
  padding: 1vh;
}

.narvbarLinksTranslateSpan:hover {
  color: #4a154d;
  border: 1px solid #4a154d;
}

.navbarLinksUser > li:hover {
  color: #4a154d;
}

.navbarLinksUser > li {
  padding: 5px;
  cursor: pointer;
  margin: auto 10px auto 0;
}

.navbarLinksUser {
  display: flex;
  min-width: 250px;
  justify-content: space-between;
  margin-right: 20px;
  list-style: none;
  margin-top: auto;
  margin-bottom: auto;
}

/*  CSS for active navbar link  */

.navbarMenuActive {
  display: inline-block;
  position: relative;
  color: white;
}

.navbarMenuActive:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  background-color: #ef711d;
}

.navbarMenuActive::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.flagIcon {
  margin-right: 5px;
}

// CSS for when the width is below 768px (initially doesn't show)
.navbarResponsiveContainer {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  display: flex;
  justify-content: space-between;
  //min-width: 220px;
  align-items: center;
  padding: 10px;
}
.navbarResponsiveContainer {
  display: none;
}

.navbarSandwishMenuIcon {
  font-size: 4vh !important;
}

.navbarResponsiveWrapper {
  position: relative;
  display: inline-block;
}

.navbarSandwishMenuIconSpan {
  cursor: pointer;
}

.navbarResponsiveDropdown {
  font-family: "Poppins", sans-serif;
  padding: 10px;
  list-style: none;
  display: none;
  position: absolute;
  z-index: 11;
  background-color: #4a154d;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.navbarResponsiveDropdownBtn {
  color: white !important;
}

.navbarResponsiveDropdownQuicklinks {
  display: flex !important;
}

.navbarSandwishMenuIconSpan:hover + .navbarResponsiveDropdown,
.navbarResponsiveDropdown:hover {
  display: block;
  position: absolute;
  right: 0%;
}

.navbarResponsiveDropdownBtn {
  cursor: pointer;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.navbarResponsiveDropdownItem .navbarResponsiveSubListDropdown {
  position: absolute;
  top: 0;
  right: 100%;
  display: none;
  background-color: #4a154d;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  font-family: "Poppins", sans-serif;
  padding: 20px;
  list-style: none;
  //min-width: 200px;
  z-index: 99 !important; ///TODO:Why doesn't this work?
}

.navbarResponsiveDropdownItem {
  position: relative;
}

.responsiveDropDownMenuList > li,
.navbarResponsiveSubListDropdown > li {
  padding: 5px;
}

.responsiveDropDownMenuList,
.navbarResponsiveSubListDropdown {
  min-width: 200px;
}

.navbarResponsiveDropdownQuicklinks:hover .navbarResponsiveSubListDropdown,
.navbarResponsiveDropdownItem:hover .navbarResponsiveSubListDropdown {
  display: block;
}

.navbarResponsiveDropdownItem:hover .navbarResponsiveSubListDropdown {
  display: block;
}

.narvbarResponsiveLinksTranslateSpan {
  cursor: pointer;
  background-color: transparent;
  font-size: 2vh;
  padding: 1vh;
  border: none;
  color: white;
}

//// STYLE QUERIES

@media all and (max-width: 410px) {
  .responsiveDropDownMenuList > li,
  .navbarResponsiveSubListDropdown > li,
  .navbarResponsiveDropdownBtn {
    font-size: 12px;
  }
}

@media all and (max-width: 550px) {
  .navbarHTISInitial {
    font-size: 20px !important;
  }
  .navbarP1 {
    font-size: 21px !important;
  }
  .navbarP2 {
    font-size: 16px !important;
  }
  .navbarP3 {
    font-size: 18px !important;
  }
  .navbarP4 {
    font-size: 21px !important;
  }
  .navbarLogo {
    width: 90px;
    padding-left: 10px;
  }
  .navbarHeader {
    flex: 12;
  }
}

@media all and (max-width: 768px) {
  .navbarResponsiveContainer {
    display: block;
  }
  .navbarLinks,
  .navbarMenu {
    display: none;
  }
}

@media all and (max-width: 850px) {
  .navbarHTISInitial {
    font-size: 32px;
  }
  .navbarP1 {
    font-size: 30px;
  }
  .navbarP2 {
    font-size: 25px;
  }
  .navbarP3 {
    font-size: 30px;
  }
  .navbarP4 {
    font-size: 35px;
  }
  .navbarLinks {
    flex-direction: column;
    min-width: 120px;
  }
  .navbarMenuItem,
  .narbarLink {
    font-size: 16px;
  }
}

@media all and (min-width: 850px) {
  .navbarP1 {
    font-size: 40px; //was 45px;
  }

  .navbarP2 {
    font-size: 33px; //was 37px;
  }

  .navbarP3 {
    font-size: 37px;
  }

  .navbarP4 {
    font-size: 40px; //was 45px;
  }
}

@media all and (max-width: 350px) {
  .navbarMiddle {
    height: 250px;
  }
}
