.cycleContainer1 {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.cycleContainer1 > h1 {
  color: #ef711d;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.cycleContainer1 > p {
  width: 70%;
  margin: auto;
  padding: 10px;
  line-height: 50px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  word-spacing: 5px;
}

.cycleContainer1 > p:first-letter {
  font-weight: 700;
  font-size: 30px;
}

.cycleContainer1 > p > ul > li {
  margin-left: 50px;
}

.cycleSpansWrapper {
  padding: 10px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
}

.cycleSpan {
  height: 250px;
  width: 450px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  display: flex;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.cycleSpansImgSupplies {
  background-position-y: 50% !important;
}

.cycleSpan > p {
  margin: auto;
  color: white;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.15em;
  stroke: solid black;
  font-family: "Varela", sans-serif;
}

/** CSS for photo gallery **/

.cyclePhotosContainer {
  padding: 10px;
}

.cyclePhotosRow,
.tigersNightsPhotosRow {
  margin-top: 20px;
  display: grid;
  /*Repeat grid-template-rows enough to fit all contents. This was made as an in-line CSS*/
  grid-template-columns: 400px 400px 400px;
  grid-gap: 5px;
  justify-content: center;
  flex-wrap: wrap;
}

.cyclePhotosRow .cyclePhotosColumn,
.tigersNightsPhotosRow .tigersNightsPhotosColumn {
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.cyclePhotosColumn > img,
.tigersNightsPhotosColumn > img {
  object-fit: cover;
  width: 500px;
  height: 500px;
  max-height: 100%;
  cursor: pointer;
}

.cyclePhotosColumn:hover,
.tigersNightsPhotosColumn:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.cyclePhotosColumn > img:hover,
.tigersNightsPhotosColumn > img:hover {
  border: 1px solid lightgray;
  opacity: 95%;
}

.cyclePhotosSocialIcons {
  width: 100%;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.cyclePhotosSocialIcons > p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 25px;
}

.cyclePhotosSocialIcons > span {
  width: 30%;
  height: fit-content;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.cyclePhotosSocialIcon {
  transition: all 0.2s ease-in-out;
}

.cyclePhotosSocialIcon:hover {
  opacity: 90%;
  box-shadow: 0 4px 2px -2px gray;
  transform: scale(1.2);
}

.cyclePhotosRow:after,
.tigersNightsPhotosRow:after {
  content: "";
  display: table;
  clear: both;
}

.cyclePhotosModal,
.tigersNightsPhotosModal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.cyclePhotosModalContent,
.tigersNightsPhotosModalContent {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}

.cyclePhotosCloseBtn,
.tigersNightsPhotosCloseBtn {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
}

.cyclePhotosCloseBtn:hover,
.cyclePhotosCloseBtn:focus,
.tigersNightsPhotosCloseBtn:hover,
.tigersNightsPhotosCloseBtn:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.cyclePhotosModalSlides,
.tigersNightsPhotosModalSlides {
  display: none;
}

.cyclePhotosPrevBtn,
.cyclePhotosNextBtn,
.tigersNightsPhotosPrevBtn,
.tigersNightsPhotosNextBtn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: #9fdd3a !important;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

.cyclePhotosNextBtn,
.tigersNightsPhotosNextBtn {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.cyclePhotosPrevBtn:hover,
.cyclePhotosNextBtn:hover,
.tigersNightsPhotosPrevBtn:hover,
.tigersNightsPhotosNextBtn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.cyclePhotosNumberTxt,
.tigersNightsPhotosNumberTxt {
  color: gray;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.cyclePhotosColumn > img,
.cyclePhotosModalSlides > img,
.tigersNightsPhotosColumn > img,
.tigersNightsPhotosModalSlides > img {
  margin-bottom: -4px;
}

.cyclePhotosModalSlides,
.tigersNightsPhotosModalSlides {
  overflow: hidden;
}

.cyclePhotosModalSlides > img,
.tigersNightsPhotosModalSlides > img {
  width: 100%;
  max-height: 600px;
  object-fit: contain;
}

.cyclePhotosCaptioncontainer,
.tigersNightsPhotosCaptioncontainer {
  text-align: center;
  padding: 2px 16px;
  color: white;
}

.cyclePhotosRedirectContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  font-family: "Poppins", sans-serif;
}

.cyclePhotosRedirectContainer > p {
  font-weight: bold;
  font-size: 30px;
}

.cyclePhotosRedirectContainer > div {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 20px auto;
}

.cyclePhotosRedirectBtn {
  font-size: 20px;
  padding: 10px;
  color: white;
}

.cyclePhotosRedirectBtn:hover {
  opacity: 90%;
}

.cycleGoBackArrow,
.cyclePhotosGoBackArrow,
.tigersNightsGoBackArrow {
  color: #9fdd3a;
  font-size: 60px !important;
  cursor: pointer;
}

.cyclePhotosGoBackArrow {
  margin-top: 20px;
  margin-left: 20px;
}

// RESPONSIVE DESIGN STYLES

@media all and (max-width: 430px) {
  .cyclePhotosRedirectContainer > p {
    margin-bottom: 40px;
    font-size: 25px;
  }
  .cyclePhotosRedirectContainer > div {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media all and (max-width: 450px) {
  .cyclePhotosColumn > img,
  .tigersNightsPhotosColumn > img {
    width: 300px !important;
    height: 300px !important;
  }
}

@media all and (max-width: 550px) {
  .cycleSpan {
    margin-top: 30px !important;
    height: 200px;
    width: 350px;
  }
  .cycleGoBackArrow,
  .cyclePhotosGoBackArrow,
  .tigersNightsGoBackArrow {
    font-size: 50px !important;
  }
}

@media all and (max-width: 600px) {
  .cyclePhotosColumn > img,
  .tigersNightsPhotosColumn > img {
    width: 400px;
    height: 400px;
  }
}

@media all and (max-width: 700px) {
  .suppliesListTextsWrapper > h1 {
    font-size: 30px;
  }
  .suppliesListTextsWrapper > h2 {
    font-size: 26px;
  }
  .cyclePhotosSocialIcons > span {
    width: 100%;
    justify-content: space-evenly;
  }
}

@media all and (max-width: 750px) {
  .cyclePhotosRedirectContainer > div {
    display: grid;
    grid-template-columns: auto auto;
    height: 150px;
    justify-content: space-evenly;
    margin-top: 20px;
    grid-gap: 30px;
  }
}

@media all and (max-width: 800px) {
  .cyclePhotosRow,
  .tigersNightsPhotosRow {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media all and (max-width: 950px) {
  .cycleSpansWrapper {
    flex-direction: column;
    align-items: center;
  }
  .cycleSpan {
    margin-top: 50px;
  }
}

@media all and (max-width: 1000px) {
  .cyclePhotosRedirectContainer > div {
    width: 90%;
  }
}

@media all and (max-width: 1100px) {
  .cyclePhotosRow,
  .tigersNightsPhotosRow {
    grid-template-columns: 500px 350px !important;
  }
}

@media all and (max-width: 1200px) and (min-width: 950px) {
  .cycleSpan {
    height: 200px;
    width: 400px;
  }
}

@media all and (max-width: 1200px) {
  .cycleContainer1 > p {
    width: 90%;
  }
}

@media all and (max-width: 1300px) {
  .cyclePhotosRow,
  .tigersNightsPhotosRow {
    grid-template-columns: 350px 350px 350px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
