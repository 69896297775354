.popupContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
}

.popupContent {
  padding: 30px;
  background: linear-gradient(232.09deg, #ef711d 39.08%, #a6a6a6 125%);
  border: 3px solid #ffffff;
  width: 40%;
  border-radius: 5px;
  box-shadow: 0 0 10px lightgray;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popupCloseBtn {
  height: fit-content;
  color: white;
  float: right;
  cursor: pointer;
  //font-size: xx-large;
}

.popupCloseBtn:hover {
  color: #4a154d;
}

.popupTexts {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}

.popupInline {
  display: flex;
  width: 100%;
}

.popupHeader {
  margin: auto;
  font-weight: 700;
  //font-size: 30px;
  letter-spacing: 0.1em;
  color: #ffffff;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-family: "Josefin Sans", sans-serif;
}

.popupImgWrapper {
  margin-top: 10px;
  margin-bottom: 20px;
}

.popupImg {
  width: 300px;
  height: 300px;
  object-fit: contain;
}

.popupText {
  margin: 15px 0px; //font-size: 25px;
  line-height: 29px;
  letter-spacing: 0.05em;
  color: #ffffff;
  font-family: "Varela Round", sans-serif;
}

.popupActionSpan {
  width: fit-content;
  margin: auto;
  padding: 20px;
  background-color: transparent;
  color: white;
  border: 2px solid #ffffff;
  letter-spacing: 2px;
  border-radius: 5px;
  font-weight: bold;
  //font-size: 18px;
  font-weight: 700;
  font-family: "Varela Round", sans-serif;
}

.popupActionBtn {
  width: fit-content;
  margin: auto;
  padding: 20px;
  background-color: transparent;
  color: white;
  border: 2px solid #ffffff;
  letter-spacing: 2px;
  font-weight: bold;
  //font-size: 18px;
  font-weight: 700;
  font-family: "Varela Round", sans-serif;
  cursor: pointer;
  text-decoration: none !important;
}

.popupActionBtn:hover {
  background-color: #ffffff;
  color: #4a154d;
  border: none;
}

.homePopupTimeIsUp {
  font-family: "Varela Round", sans-serif;
  //font-size: 70px;
  color: white;
}

.popupSingleInterval {
  display: inline-block;
  //font-size: 1.4em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
  color: white;
}

.popupSingleInterval span {
  display: block;
  //font-size: 4.5rem;
}

/// RESPONSIVE DESIGN

@media all and (max-width: 500px) {
  /*.popupContent {
    width: 90% !important;
    transform: translate(5%, 50%) !important;
  }*/
}

@media all and (max-width: 700px) {
  .popupContent {
    width: 80%;
    //transform: translate(10%, 50%);
  }
}

@media all and (max-width: 900px) {
  .homePopupTimeIsUp {
    font-size: 4vh !important;
  }
}

@media all and (max-width: 1000px) {
  .popupCloseBtn {
    font-size: xx-large;
  }
  .popupHeader {
    font-size: 20px !important;
  }
  .popupText {
    font-size: 18px !important;
  }
  .popupActionSpan,
  .popupActionBtn {
    font-size: 14px !important;
  }
}

@media all and (max-width: 1150px) {
  .popupCloseBtn {
    font-size: xx-large;
  }
  .popupHeader {
    font-size: 25px;
  }
  .popupText {
    font-size: 20px;
  }
  .popupActionSpan,
  .popupActionBtn {
    font-size: 16px;
  }
  .homePopupTimeIsUp {
    font-size: 5vh;
  }
}

@media all and (min-width: 1151px) {
  .popupCloseBtn {
    font-size: xx-large;
  }
  .popupHeader {
    font-size: 30px;
  }
  .popupText {
    font-size: 25px;
  }
  .popupActionSpan,
  .popupActionBtn {
    font-size: 18px;
  }
  .homePopupTimeIsUp {
    font-size: 5vh;
  }
  .popupSingleInterval {
    font-size: 1.4em;
  }
  .popupSingleInterval span {
    font-size: 4.5rem;
  }
}
