.FourOFourContainer {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 60px;
}

.FourOFourContainer > img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
}

.FourOFourContainer > h1 {
  font-size: 50px;
  font-family: "Josefin Sans", sans-serif;
}

.FourOFourContainer > p {
  font-family: "Varela", sans-serif;
  font-size: 25px;
  text-align: center;
}

.FourOFourBtn {
  padding: 10px;
  border: none;
  font-family: "Varela", sans-serif;
  cursor: pointer;
  border: none;
  background: #ef711d;
  padding: 15px 20px;
  color: white;
  font-size: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.FourOFourBtn:hover {
  color: #ef711d;
  background-color: white;
  border: 1px solid #ef711d;
}

/// STYLE QUERIES

@media all and (max-width: 550px) {
  .FourOFourContainer > img {
    width: 80%;
    height: 80%;
  }
  .FourOFourContainer > h1 {
    font-size: 40px;
  }

  .FourOFourContainer > p {
    font-size: 20px;
    line-height: 20px;
  }
}
