.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.homeThreeCircles {
  display: flex;
  width: 75%;
  justify-content: space-around;
  align-self: center;
  margin-top: 4vh;
  margin-bottom: 4vh;
}

.homeCircle {
  clip-path: circle(120px);
  height: 250px;
  width: 250px;
  background-size: cover !important;
  display: flex;
  cursor: pointer;
}

.homeCircleInquire {
  clip-path: circle(120px);
  height: 250px;
  width: 250px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  display: flex;
  cursor: pointer;
  background-color: #907048 !important;
}

.homeCircle:hover {
  opacity: 90%;
}

.homeCircle > p,
.homeCircleInquire > p {
  margin: auto;
  color: white;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0.15em;
  stroke: solid black;
  font-family: "Varela", sans-serif;
}

.homeHTISNumbers {
  width: 100%;
  height: 400px;
  /*background-image: url("../../images/Colleagues.jpg");
  box-shadow: inset 0 0 999999px rgba(74, 21, 77, 0.8);*/
  background: linear-gradient(to top, #4a154db3, #4a154db3),
    url("../../images/HTISNumbers.jpg") no-repeat center top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 4vh;
}

.homeHTISNumbersHeader {
  color: white;
  font-family: "Varela", sans-serif;
  font-size: 60px;
  text-align: center;
}

.homeHTISNumbersIconsWrapper {
  display: flex;
  justify-content: space-evenly;
  font-family: "Poppins", sans-serif;
}

.homeHTISNumbersIconsWrapper > span {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeHTISNumbersIcon {
  font-size: 10vh !important;
}

.homeHTISNumbersIcon,
.homeHTISNumbersIconsWrapper > span > p {
  color: white;
}

.homeHTISNumbersIconsWrapper > span > p {
  margin-top: 5px;
}

.homeHTISNumber {
  font-size: 25px;
  font-weight: 600;
}

.homeHTISNumberInfo {
  font-size: 20px;
}

.homeCertifications {
  display: flex;
  justify-content: space-around;
  width: 80%;
  align-self: center;
  margin-top: 4vh;
  margin-bottom: 10vh;
}

.homeCertificationsLogo {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.homeLatestNewsBtn {
  font-weight: 500;
  font-size: 20px;
  margin: 50px auto;
  background: #4a154d;
  color: #ffffff;
  border: none;
  display: flex;
  padding: 10px;
  cursor: pointer;
}

.homeLatestNewsBtn:hover {
  border: 2px solid #ef711d;
  color: #ef711d;
  background-color: white;
}

.homeLatestNewsWrapper {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
}

.homeLatestNewsHeader {
  font-family: "Varela Round", sans-serif;
  text-align: center;
  padding: 20px 0px;
}

.homeNewsCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 30%;
  cursor: pointer;
}

.homeNewsCardDefaultImg {
  /*
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      rgba(117, 19, 93, 0.73)
    ),
    url("../../images/UnitedNations.jpeg");*/
  background: linear-gradient(to top, #ef711db3, #4a154db3),
    url("../../images/UnitedNations.jpeg") no-repeat center;
  //width: 100%;
  height: 300px;
  background-size: cover;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.homeNewsCardImg {
  width: 100%;
  //height: 300px;
  object-fit: contain;
  margin: auto;
}

.homeNewsCardDefaultImgLogo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin: auto;
}

.homeNewsCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.homeNewsCardTexts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.homeNewsCardTextsTitle {
  font-weight: bold;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.homeNewsCardTexts > b {
  font-family: "Varela", sans-serif;
}

.homeNewsCardDateInline {
  display: flex;
}

.homeNewsCardDateInline > p {
  padding-left: 10px;
  margin: auto;
}

.homeNewsCardDesc {
  overflow: hidden;
  max-width: 100%;
  max-height: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.homeLatestNewsLoader {
  margin-left: auto;
  margin-right: auto;
}

.homeCircleText {
  background-color: rgba(0, 0, 0, 0.5);
}

/// STYLE QUERIES

@media all and (min-width: 1900px) {
  .homeThreeCircles {
    width: 90%;
  }
}

@media all and (max-width: 1300px) {
  .homeHTISNumberInfo {
    font-size: 18px;
    width: 50%;
    text-align: center;
  }
}

@media all and (max-width: 1200px) {
  .homeThreeCircles {
    width: 100%;
  }
}

@media all and (max-width: 1050px) {
  .homeLatestNewsWrapper {
    width: 100%;
  }
}

@media all and (max-width: 1000px) {
  .homeHTISNumbersIcon {
    font-size: 8vh;
  }
  .homeHTISNumberInfo {
    font-size: 16px;
  }
  .homeCertificationsLogo {
    width: 100px;
    height: 100px;
  }
}

@media all and (max-width: 900px) {
  .homeThreeCircles {
    width: 100%;
  }
  .homeNewsCard {
    width: 40%;
  }
}

@media all and (max-width: 768px) {
  .homeThreeCircles {
    flex-direction: column;
    align-items: center;
  }
  .homeCircle,
  .homeCircleInquire {
    /*clip-path: circle(250px);
    height: 500px;
    width: 500px;*/
    margin-bottom: 50px;
  }
}

@media all and (max-width: 750px) {
  .homeHTISNumbers {
    height: fit-content;
    padding: 10px;
  }
  .homeHTISNumbersHeader {
    font-size: 40px;
    margin: 30px auto;
  }
  .homeHTISNumbersIconsWrapper {
    flex-direction: column;
  }
  .homeHTISNumbersIconsWrapper > span {
    margin-top: 40px;
  }
  .homeHTISNumber {
    font-size: 35px;
  }
  .homeHTISNumberInfo {
    font-size: 30px !important;
    width: 90% !important;
  }
}

@media all and (max-width: 650px) {
  .homeHTISNumbersHeader {
    font-size: 45px;
  }
  .homeHTISNumber {
    font-size: 20px;
  }
  .homeHTISNumbersIcon {
    font-size: 6vh;
  }
  .homeHTISNumberInfo {
    font-size: 15px;
  }
  .homeNewsCard {
    width: 60% !important;
  }
}

@media all and (max-width: 600px) {
  .homeCircle,
  .homeCircleInquire {
    /*clip-path: circle(180px) !important;*/
    margin-bottom: 30px !important;
  }
  .homeCertifications {
    flex-direction: column;
    align-items: center;
  }
  .homeCertificationsLogo {
    width: 200px !important;
    height: 200px !important;
    margin-top: 50px;
  }
  .homeLatestNewsHeader {
    font-size: 30px;
  }
  .homeHTISNumbersHeader {
    font-size: 40px;
  }
}

@media all and (max-width: 450px) {
  .homeNewsCard {
    width: 80% !important;
  }
}

@media all and (max-width: 350px) {
  .homeLatestNewsHeader {
    font-size: 25px;
  }
}
