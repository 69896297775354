.serviceContainer {
}

.serviceTop {
  /*background-image: url("../../images/Colleagues.jpg");
  box-shadow: inset 0 0 999999px rgba(74, 21, 77, 0.8);*/
  /*background: linear-gradient(to top, #4a154db3, #4a154db3),
  url("../../images/SchoolBus.JPG") no-repeat center;
  background-size: cover;*/
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden; /* Clip the background image to the element's dimensions */
  background: linear-gradient(to top, #4a154db3, #4a154db3);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.serviceTopHeader {
  color: #ef711d;
  font-family: "Varela", sans-serif;
  font-size: 60px;
  text-align: center;
}

.serviceText {
  width: 70%;
  margin: auto;
  padding: 50px;
  line-height: 50px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  word-spacing: 5px;
}

.serviceText:first-letter {
  font-weight: 700;
  font-size: 30px;
}

.serviceRedirectContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.serviceRedirectContainer > div {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.serviceRedirectContainer p {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 30px;
  color: #ef711d;
}

.serviceRedirectContainer span {
  display: inline-block;
  margin: 5px;
}

.serviceRedirectBtn {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  border: 1px solid #333333;
}

.serviceRedirectBtn:hover {
  background-color: rgba(211, 211, 211, 0.362);
}

.serviceRedirectBtn:nth-child(3n + 1):hover {
  border: 1px solid #ef711d;
  color: #ef711d;
}

.serviceRedirectBtn:nth-child(3n + 2):hover {
  border: 1px solid #4a154d;
  color: #4a154d;
}

.serviceRedirectBtn:nth-child(3n):hover {
  border: 1px solid #9fdd3a;
  color: #9fdd3a;
}

.servicesText {
  background-color: rgba(0, 0, 0, 0.5);
}

// RESPONSIVE DESIGN STYLES

@media all and (max-width: 500px) {
  .serviceTopHeader {
    font-size: 40px !important;
  }
  .serviceRedirectContainer > p {
    width: 80%;
  }
}

@media all and (max-width: 550px) {
  .serviceRedirectContainer > div {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
}

@media all and (max-width: 750px) {
  .serviceRedirectContainer > div {
    grid-template-columns: auto auto !important;
  }
  .serviceRedirectContainer > div {
    justify-content: space-between;
  }
  .serviceRedirectContainer > p {
    font-size: 25px;
  }
}

@media all and (max-width: 850px) {
  .serviceTopHeader {
    font-size: 50px;
  }
  .serviceText {
    width: 100%;
  }
}

@media all and (max-width: 1100px) {
  .serviceRedirectContainer > div {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .serviceRedirectBtn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 60px;
  }
}

@media all and (max-width: 1200px) {
  .serviceRedirectContainer > div {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
}
