.deletePopupContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.deletePopupWrapper {
  background-color: rgba(255, 255, 255, 0.9);
  width: 40%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px lightgray;
  position: fixed;
  top: 30%;
  left: 30%;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.deletePopupWrapper > p {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.deletePopupBtns {
  margin: auto;
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.deletePopupBtns > button {
  border: none;
  padding: 10px;
  border-radius: 5%;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  width: 30%;
}

.deletePopupBtns > button:hover {
  background-color: #4a154d;
  color: white;
}
