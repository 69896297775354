.applicantLogsButton {
  margin: 20px auto;
  display: flex;
  border: none;
  background: #ef711d;
  padding: 12px 18px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.applicantLogsButton:hover {
  background: #9fdd3a;
}

.applicantLogsTable {
  width: 100%;
  text-align: center;
}

.applicantLogsTableTr {
  border-bottom: 1px solid lightgray;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  padding: 15px;
  border-bottom: 1px solid lightgray;
}

.applicantLogsTableTr > th {
  font-weight: 700;
}

.applicantLogsTableTr > td {
  padding: 10px 0;
}

.applicantLogsIcons {
  display: flex;
  justify-content: space-evenly;
}

.applicantLogsIcon {
  cursor: pointer;
}

.applicantLogsIcon:hover {
  opacity: 80%;
}

//////

.applicantLogsEditMeetingPopupForm > label {
  display: flex;
  justify-content: space-between;
  height: 60px;
}

.applicantLogsEditMeetingPopupForm > label > span {
  display: flex;
  margin: auto 0 auto 0;
  font-weight: 600;
  font-size: 18px;
}

.applicantLogsEditMeetingPopupForm > label > input,
.applicantLogsEditMeetingPopupForm > label > select {
  margin-bottom: 20px;
  display: block;
  width: 250px;
  height: 100%;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  border: 1px solid #8b8b8b;
  color: #8b8b8b;
  outline-color: #9fdd3a !important;
}

.applicantLogsEditMeetingPopBtns {
  margin: auto;
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.applicantLogsEditMeetingPopBtns > button {
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  //width: 40%;
}

.applicantLogsEditMeetingPopBtns > button:hover {
  background-color: #4a154d;
  color: white;
}

.applicantLogsTableTrPassedDate {
  background-color: rgba(211, 211, 211, 0.25);
}
