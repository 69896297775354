.newApplicationContainer,
.newApplicationSubmitted {
  background-color: #4a154d;
  padding: 50px 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
} 

.newApplicationForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.newApplicationFormWrapper {
  column-count: 3;
}

input[type="file"] {
  display: none;
}

.newApplicationFormAttachIcon {
  float: right;
}

#newApplicationFormResume,
#newApplicationFormPhoto,
#newApplicationFormTest {
  cursor: pointer;
}

.newApplicationContainer {
  box-sizing: border-box;
}

.newApplicationCol1,
.newApplicationCol2,
.newApplicationCol3,
#newApplicationFormResume,
#newApplicationFormPhoto,
#newApplicationFormTest,
.newApplicationCustomPosition {
  background-color: white;
  margin-bottom: 20px;
  display: block;
  width: 350px;
  height: 60px;
  padding: 10px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  outline-color: #9fdd3a !important;
}

.newapplicationcol1:empty,
.newapplicationcol2:empty,
.newapplicationcol3:empty {
  color: black;
}

.newApplicationRequiredCol {
  display: flex;
  justify-content: space-between;
}

.newApplicationCol1 > input,
.newApplicationCol2 > input,
.newApplicationCol3 > input,
.newApplicationRequiredCol > select {
  border: none;
  width: 100%;
  height: 100%;
}


.newApplicationRequiredCol > p {
  color: red;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  padding: 10px;
  text-align: center;
  float: right;
}
/**/
#newApplicationFormResume > p {
  padding: 5px 10px !important;
}
/**/
.newApplicationRequiredNote {
  color: red;
  text-align: center;
}

.newApplicationRequiredNote > span {
  color: white;
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
}

.newApplicationForm > button {
  display: flex;
  margin: auto;
  border: none;
  background: #ef711d;
  padding: 12px 18px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.newApplicationForm > button:focus,
.newApplicationRequiredCol > input:focus,
.newApplicationRequiredCol > select:focus {
  outline: none;
}

.newApplicationForm > button:hover {
  background: #9fdd3a;
}

.newApplicationFormlastContacted > label {
  display: flex;
  justify-content: space-between;
}

.newApplicationFormlastContacted > label > input {
  border: none;
  float: right;
  font-size: 18px;
  margin-top: auto;
}

.newApplicationFormlastContacted > label > input:focus {
  outline: none;
}

.newApplicationFormImgPreview {
  position: relative;
  z-index: 10;
  float: right;
  width: 25px;
  height: 25px;
  object-fit: contain;
  border: 0.5px solid lightgray;
}

.newApplicationFormImgPreview:hover {
  transform: scale(20);
  border: none;
  z-index: 999 !important;
}

.newApplicationFormImgPreviewSpan{
  float: right;
  position: relative;
}

.newApplicationFormImgPreviewLength{
  color: lightgray;
  font-size: 15px;
}

/***********/

.newApplicationSubmitted > p {
  color: #9fdd3a;
  font-family: "Varela", sans-serif;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
}

.newApplicationSubmitted > span {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.newApplicationSubmittedBtn {
  margin: 10px 0;
  width: 300px;
  height: 50px;
  border: none;
  background: #ef711d;
  padding: 12px 18px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}


.newApplicationSubmittedBtn:hover {
  border: 1px solid #ef711d;
  background-color: white;
  color: #ef711d;
}

.newApplicationSelectRequired {
  display: flex;
  justify-content: space-between;
}

.newApplicationSelectRequired > p {
  color: red;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  padding: 10px;
  text-align: center;
  float: right;
}