.clubsContainer {
  width: 100%;
  background: linear-gradient(to top, #4a154db3, #4a154db3),
    url("../../images/cycles/high/Party.JPG") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.clubsContainer > h1 {
  margin: 40px;
  color: #ef711d;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.clubsWrapper {
  margin: auto;
  width: 80%;
  display: grid;
  grid-template-columns: auto auto auto;
}

.clubSingle {
  cursor: pointer;
  padding: 20px;
  width: (170px) * 2;
  margin: 10px auto;
  .clubTop,
  .clubBottom {
    height: 50px;
    overflow: hidden;
    position: relative;
    width: 170px;
    margin: 0 auto;
    .clubRing {
      border-radius: 100%;
      position: absolute;
      width: 170px;
      height: 170px;
      border: 3px solid lightgray;
      border-width: 8px;
      &:nth-child(2) {
        border-width: 4px;
        width: (170px)-16;
      }
      &:nth-child(3) {
        border-width: 2px;
        width: (170px)-31;
        &:after {
          content: "";
          width: 18px;
          position: absolute;
          border-top: 1px solid lightgray;
          left: 50%;
          margin-left: -9px;
        }
        &:before {
          content: "";
          width: 32px;
          position: absolute;
          border-top: 1px solid lightgray;
          margin-left: -16px;
          left: 50%;
        }
      }
    }
  }
  .clubTop {
    .clubRing {
      &:nth-child(1) {
        top: 0;
        left: 0;
      }
      &:nth-child(2) {
        top: 10px;
        left: 8px;
      }
      &:nth-child(3) {
        top: 20px;
        left: 16px;
        &:after {
          top: 17px;
        }
        &:before {
          top: 22px;
        }
      }
    }
  }
  .clubBottom {
    .clubRing {
      &:nth-child(1) {
        bottom: 0;
        left: 0;
      }
      &:nth-child(2) {
        bottom: 10px;
        left: 8px;
      }
      &:nth-child(3) {
        bottom: 20px;
        left: 16px;
        &:after {
          bottom: 17px;
        }
        &:before {
          bottom: 22px;
        }
      }
    }
  }

  .clubText {
    text-align: center;
    text-transform: uppercase;
    font-family: "Varela", sans-serif;
    font-size: 30px;
    font-weight: 900;
    color: lightgray;
    height: 55px;
    line-height: 35px;
    vertical-align: middle;
    display: table-cell;
    width: (170px) * 2;
    position: relative;
    left: 7px;
    letter-spacing: 14px;
  }
}

.clubSingle:hover {
  .clubRing {
    border-color: #fff;
    &:nth-child(3) {
      &:after {
        border-top-color: #fff;
      }
      &:before {
        border-top-color: #fff;
      }
    }
  }
  .clubText {
    color: #fff;
  }
}

/// STYLE QUERIES

@media all and (max-width: 550px) {
  .clubsWrapper {
    grid-template-columns: auto !important;
  }
}

@media all and (max-width: 700px) and (min-width: 550px) {
  .clubSingle {
    width: (130px) * 2;
    .clubTop,
    .clubBottom {
      height: 50px;
      width: 130px;
      .clubRing {
        width: 130px;
        height: 130px;
        &:nth-child(2) {
          width: (130px)-16;
        }
        &:nth-child(3) {
          width: (130px)-31;
          &:after {
            content: "";
            width: 18px;
          }
          &:before {
            content: "";
            width: 32px;
          }
        }
      }
    }
    .clubText {
      font-size: 25px;
      width: (130px) * 2;
    }
  }
}

@media all and (max-width: 1050px) {
  .clubsWrapper {
    grid-template-columns: auto auto;
  }
}

@media all and (max-width: 1300px) {
  .clubsWrapper {
    width: 100%;
  }
}
