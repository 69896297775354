.alumniContainer {
}

.alumniContainer > h1 {
  margin: 40px;
  color: #ef711d;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.alumniCollegesWrapper {
  margin: auto;
  width: 80%;
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.alumniCollegeLogo {
  margin: auto;
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.alumniTabList {
  width: fit-content;
}

.alumniTab {
  font-size: 18px;
}

.alumniStatsWrapper > span > p {
  font-family: "Poppins", sans-serif;
  //font-size: 18px;
  width: 80%;
  margin: auto;
  line-height: 25px;
  text-align: center;
}

.alumniStatsWrapper > span {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 150px;
}

.alumniStatsIcon {
  width: 50px !important;
  height: 50px !important;
  color: rgba(0, 0, 0, 0.5);
  margin: auto;
}

.alumniStat {
  font-weight: 600;
}

.alumniUniversitiesLoading {
  width: 100px !important;
  height: 100px !important;
}

/// STYLE QUERIES

@media all and (max-width: 500px) {
  .alumniTab {
    font-size: 12px !important;
  }
}
@media all and (max-width: 530px) {
  .alumniCollegesWrapper {
    grid-template-columns: auto auto !important;
  }
}

@media all and (max-width: 650px) {
  .alumniCollegesWrapper {
    grid-template-columns: auto auto auto;
  }
}

@media all and (max-width: 800px) {
}

@media all and (max-width: 950px) {
  .alumniCollegesWrapper {
    width: 100%;
    //grid-template-columns: auto auto auto;
  }
}

@media all and (max-width: 1000px) {
  .alumniCollegeLogo {
    width: 150px;
    height: 150px;
  }
  .alumniStatsIcon {
    width: 35px !important;
    height: 35px !important;
    color: rgba(0, 0, 0, 0.5);
  }
  .alumniStatsWrapper > span > p {
    font-size: 16px;
    width: 90%;
  }
  .alumniStatsWrapper {
    grid-template-columns: auto auto auto;
    width: 100%;
  }
}
