.agendaContainer {
  padding: 20px;
}

.agendaContainer > h1 {
  margin: 20px 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.agendaWrapper {
  width: 70%;
  margin: auto;
}

.agendaWrapper > h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 30px 0;
}

.agendaEvent {
  display: flex;
  font-family: "Josefin Sans", sans-serif;
  text-indent: 15px;
  font-size: 18px;
}

.agendaEvent > p {
  text-transform: uppercase;
  color: #4a154d;
  font-weight: 600;
}

.agendaEventIcon {
  color: #ef711d;
}

.agendaEventIconPast {
  color: #9fdd3a;
}

.agendaBtn {
  display: flex;
  margin: 20px auto;
  border: none;
  background: #ef711d;
  padding: 12px 18px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.agendaLoaderSpan {
  width: 100%;
  display: block;
  text-align: center;
}

.agendaLoader {
  margin: 40px auto;
}
