.applicantNotesContainer > h2 {
  margin: 20px auto;
}

.applicantNotesTable {
  width: 100%;
  text-align: center;
}

.applicantNotesTableTr {
  border-bottom: 1px solid lightgray;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  padding: 15px;
  border-bottom: 1px solid lightgray;
}

.applicantNotesTableTr > th {
  font-weight: 700;
}

.applicantNotesTableTr > td {
  padding: 10px 0;
}

.applicantNotesTableActionIcons {
  display: flex;
  justify-content: space-evenly;
}

.applicantNotesTableActionIcon {
  cursor: pointer;
}

.applicantNotesTableActionIcon:hover {
  opacity: 80%;
}

.applicantNotesAddTextInput {
  width: 100%;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
}

.applicantNotesAddBtnsWrapper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
}

.applicantNotesAddBtn {
  border: 1px solid lightgray;
  cursor: pointer;
  padding: 5px;
  min-width: 100px;
}

.applicantNotesAddBtn:hover {
  color: #ef711d;
}

.applicantNotesAddNewNoteBtn {
  width: fit-content;
  padding: 10px;
  margin: auto;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  border: none;
  cursor: pointer;
  background-color: #ef711d;
  color: white;
}

.applicantNotesAddNewNoteBtn:hover {
  background-color: #9fdd3a;
}
