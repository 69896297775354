.contactUsContainer {
  width: 100%;
  height: fit-content;
  background: #4a154d;
  box-sizing: border-box;
  padding: 35px;
}

.contactUsHeader {
  font-weight: 700;
  font-size: 35px;
  color: white;
  font-family: "Josefin Sans", sans-serif;
  margin-bottom: 10px;
}

#contactUsForm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-items: center;
}

.contactUsField {
  margin: 8px 10px 6px 0;
  padding: 10px;
  background-color: white;
  border: none;
  width: 400px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: #8b8b8b;
  display: flex;
}

#contactUsForm input,
#contactUsForm textarea {
  width: 90%;
  height: 100%;
  border: none;
}

#contactUsForm input:focus,
#contactUsForm textarea:focus {
  outline: none !important;
}

#contactUsForm input:empty,
#contactUsForm textarea:empty {
  color: black;
}

#contactUsForm textarea {
  height: 200px;
}

.contactUsBtn {
  border: none;
  background: #ef711d;
  padding: 10px;
  cursor: pointer;
  color: white;
  font-size: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.contactUsBtn:hover {
  background: #9fdd3a;
}

.contactUsSuccessContainer {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 60px;
}

.contactUsSuccessfulMsgSentBtn {
  padding: 10px;
  border: none;
  font-family: "Varela", sans-serif;
  cursor: pointer;
  border: none;
  background: #ef711d;
  padding: 15px 20px;
  color: white;
  font-size: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.contactUsSuccessfulMsgSentBtn:hover {
  background: #9fdd3a;
}

.contactUsSuccessfulMsgSent {
  font-size: 25px;
  color: green;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
}

.contactUsValidationError {
  color: red;
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
}

/// STYLE QUERIES

@media all and (max-width: 500px) {
  .contactUsField {
    width: 95%;
    font-size: 18px;
  }
  .contactUsHeader {
    font-size: 30px;
  }
}
