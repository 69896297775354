.traditionsContainer {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.traditionsContainer > h1 {
  margin: 40px;
  color: #ef711d;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.traditionsContainer > p {
  width: 70%;
  margin: auto;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  line-height: 50px;
  font-size: 20px;
  word-spacing: 5px;
  text-indent: 50px;
}

.traditionsContainer > p:first-letter {
  font-weight: 700;
  font-size: 30px;
}

.traditionsTigersNights {
  color: #ef711d;
}

/// STYLE QUERIES

@media all and (max-width: 500px) {
  .traditionsContainer > h1 {
    font-size: 32px;
  }
}

@media all and (max-width: 700px) {
  .traditionsContainer > p {
    width: 100%;
  }
}
