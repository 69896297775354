.calendarContainer {
}

/*   Prompt CSS   */

.calendarPromptContainer,
.meetingsEditPopupContainer,
.meetingsPromptPopupContainer,
.meetingsViewPopupContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.calendarPromptWrapper {
  //background-color: rgba(255, 255, 255, 0.9);
  background-color: white;
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px lightgray;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.calendarPromptWrapper > p {
  font-size: 18px;
  font-weight: 600;
}

.calendarPromptForm {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.calendarPromptForm > span {
  display: flex;
  width: fit-content;
  height: 60px;
}

.calendarPromptForm > span > span {
  color: #ef711d;
  padding: 15px;
}

.calendarPromptForm > span {
  margin-bottom: 20px;
}

.calendarPromptForm > span > select,
.calendarPromptForm > span > input {
  display: block;
  width: 350px;
  height: 60px;
  padding: 10px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  border: 1px solid #8b8b8b;
  color: #8b8b8b;
  outline-color: #9fdd3a !important;
}

.calendarPromptBtns {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.calendarPromptBtns > button {
  border: none;
  padding: 15px;
  cursor: pointer;
  font-size: 20px;
  width: 35%;
}

.calendarPromptBtns > button:hover {
  background-color: #4a154d;
  color: white;
}

/*   End Prompt CSS   */

.meetingsViewPopupSpanWrapper {
  display: block;
  width: 100%;
  text-align: center;
}

.meetingUnclassified {
  color: #ef711d;
}

.meetingsViewPopupCloseBtn {
  display: block;
  font-size: 25px;
  cursor: pointer;
  float: right !important;
}

.meetingsViewPopupCloseBtn:hover {
  opacity: 80%;
}

.meetingsViewPopupWrapper > table {
  width: 60%;
}

.meetingsViewPopupWrapper > table > tr {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  padding: 5px;
}
