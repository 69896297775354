.applicantContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.applicantEditContainer > span {
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  width: 100%;
  padding: 10px;
}

.applicantPhoto {
  margin-top: 20px;
  width: 400px;
  height: 300px;
  object-fit: cover;
}

.applicantContainer > table,
.applicantContainer > form {
  width: 30%;
  margin: 50px 0 10px 0;
  line-height: 50px;
}

.applicantContainer > table > tr {
  //align-items: center;
  display: flex;
  justify-content: space-between;
}

.applicantContainer > table > tr > td {
  float: right;
}

.applicantStarIcon {
  color: gold;
}

.applicantResumeSpan,
.applicantTestSpan,
.applicantLogsSpan {
  display: flex;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.applicantResumeSpan:hover,
.applicantTestSpan:hover,
.applicantLogsSpan:hover {
  opacity: 80%;
}

.applicantIcons {
  width: 20%;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

.applicantIcon {
  cursor: pointer;
}

.applicantIcon:hover {
  opacity: 80%;
}

.applicantContainer > form > span {
  display: flex;
  justify-content: space-between;
}

.applicantContainer > form > span > p {
  font-weight: 700;
  margin: auto;
}

.applicantContainer > form > span > input {
  padding: 5px;
  text-align: end;
  width: 50%;
  //  word-break: break-all;
  border: 1px solid lightgray;
  margin: 5px 0;
}

/****/

.resumeContainer,
.testContainer {
  padding: 20px;
}

/*.resumeContainer{
  display: flex;
  //flex-direction: column;
  align-items: flex-start;
  margin-top: 20px; 
}*/

.resumeContainerGoBackBtn {
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}

.resumeContainerGoBackBtn:hover {
  opacity: 80%;
}

.resumeContainerImages {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.applicantContainerSpanShowPhotos {
  width: fit-content;
  position: relative;
  margin: auto;
}

.applicantContainerSpanShowPhotos > span {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 4px;
  margin: 0;
  font-size: 30px;
  cursor: pointer;
}

.applicantContainerSpanShowPhotos > span:hover {
  color: red;
}

.applicantContainerSpanShowPhotos > img {
  max-width: 100%;
  max-height: 100vh;
}

/****/

/*    UPDATE APPLICANT    */

.applicantEditContainer {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.applicantEditContainer > form {
  width: 50%;
  // margin: 50px 0;
}

.applicantEditContainer > form > label,
.applicantEditContainerLabel {
  margin: auto;
  border: 1px solid #8b8b8b;
  display: block;
  width: 400px;
  height: 60px;
  padding: 10px;
  background-color: white;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: #8b8b8b;
  outline-color: #9fdd3a !important;
  cursor: pointer;
}

.applicantEditContainer > form > label,
.applicantEditContainerLabel,
.applicantEditContainer > form > span {
  display: flex;
  justify-content: space-between;
  margin: 15px auto;
}

.applicantEditContainer > form > span > p {
  font-weight: 600;
}

.applicantEditContainer > form > span > input,
.applicantEditContainer > form > span > select,
.applicantEditContainer > form > span > span {
  width: 300px;
  text-align: center;
  padding: 5px;
}

.applicantEditContainer > form > label > img,
.applicantEditContainerLabel > img {
  width: 100px;
  object-fit: contain;
}

.applicantEditAttachIcon {
  margin: auto 5px;
}

.applicantEditContainer > form > label > img:hover,
.applicantEditContainerLabel > img:hover {
  scale: 10;
}

.applicantEditIcons {
  width: 20%;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

.applicantEditIcon {
  cursor: pointer;
}

.applicantEditIcon:hover {
  opacity: 80%;
}

.applicantLogsContainer,
.applicantNotesContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.applicantMultiSelect {
  border: 1px solid gray;
  border-radius: 5px;
  width: 300px;
  text-align: center;
  padding: 5px;
}

.applicantNotesVisibilityIcon {
  display: flex;
  margin-top: auto;
  cursor: pointer;
}

.applicantEditContainerLabelInline {
  display: flex;
}

.applicantAddEditPopupTestPreview {
  width: 100px;
}

.applicantEditPhotoPreview {
}

.applicantEditPhotoPreview > img {
  height: 50px;
  object-fit: contain;
  margin-top: -5px;
}

.applicationCVFlashTitle {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 600;
}

.applicationCVFlashWrapper {
  margin: 20px auto;
  width: 40%;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
}

.applicationCVFlashInline {
  display: flex;
  justify-content: space-between;
}

.applicationCVFlashKey {
  font-weight: 600;
}

.applicationCVFlashValue {
}
