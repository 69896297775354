.manageHomePopupContainer {
  width: 100%;
  background: #4a154d;
  box-sizing: border-box;
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.manageHomePopupContainer > h1 {
  color: #ef711d;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.manageHomePopupContainer > button {
  padding: 10px;
  background-color: white;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  cursor: pointer;
  margin: 10px 0;
}

.manageHomePopupContainer > form {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.manageHomePopupSwitch {
  color: white;
  font-family: "Poppins", sans-serif;
  margin: 10px auto;
}

.manageHomePopupWrapperSpan {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.manageHomePopupWrapper > span > label {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: white;
  margin: auto 0;
}

.manageHomePopupWrapper > span > input,
.manageHomePopupWrapper > span > select {
  background-color: white;
  text-align: center;
  margin-bottom: 20px;
  display: block;
  width: 600px;
  height: 60px;
  padding: 10px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  outline-color: #9fdd3a !important;
}

.manageHomePopupBtns {
  display: flex;
  justify-content: space-around;
}

.manageHomePopupBtns > span {
  width: 20% !important;
}

.manageHomePopupSubmitBtn,
.manageHomePopupPreviewBtn {
  width: 100%;
  padding: 10px;
  background-color: white;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

.manageHomePopupSubmitBtn:hover,
.manageHomePopupPreviewBtn:hover {
  background: #ef711d;
  color: white;
}

.manageHomePopupNoUpcomingEvents {
  color: white;
  text-align: center;
  font-family: "Varela", sans-serif;
  font-size: 25px;
  font-weight: 400;
  margin-top: 50px;
  margin-bottom: 50px;
}

.manageHomePopupNoUpcomingEventsLink {
  cursor: pointer;
  color: #9fdd3a;
  font-style: italic;
}

.manageHomePopupNoUpcomingEventsLink:hover {
  opacity: 80%;
}

.uploadImageLabel,
.deleteImageInput {
  padding: 10px;
  background-color: white;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  cursor: pointer;
  margin: 10px 0;
  text-align: center;
}

.manageHomePopupImageWrapper {
  display: flex;
  justify-content: space-around;
}

.popupImagePreviewDiv {
  display: flex;
  justify-content: center;
  margin: auto;
}

.popupImagePreview {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
