.dropDownMenuContainer {
  width: 100%;
  height: fit-content;
  background-color: #4a154d;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  //display: block;
  //margin-top: 15px;
  //width: 98.5vw;
  //top: 100%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  border-bottom: 1px solid lightgray;
  z-index: -99;
  left: 0;
  visibility: hidden;
  border-bottom: 1px solid #4a154d;
  box-shadow: inset 0 -3px 5px rgba(0, 0, 0, 0.2);
}

.dropDownMenuImg {
  width: 200px;
  height: 150px;
  object-fit: contain;
  background-repeat: no-repeat;
}

.dropDownMenuList {
  margin: auto 0;
  list-style: none;
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  line-height: 5vh;
  font-family: "Varela", sans-serif;
}

.dropDownMenuList > li {
  cursor: pointer;
  width: fit-content;
  font-size: 20px;
}

.dropDownMenuList > li:hover {
  opacity: 80%;
}

.navbarMenuItem:hover > .dropDownMenuContainer {
  opacity: 1;
  margin-top: 0;
  z-index: 399;
  visibility: visible;
  pointer-events: all;
  transition: opacity 0.2s ease, margin 0.4s ease;
}

.navbarMenuItem:hover > .dropDownMenuContainer > .dropDownItem {
  display: block;
  visibility: visible;
}

.activeLink {
  color: red;
}

// STYLE QUERIES

@media all and (max-width: 850px) {
  .dropDownMenuImg {
    display: none;
  }
}

@media all and (max-width: 1000px) {
  .dropDownItem {
    font-size: 16px;
  }
}

@media all and (max-width: 1100px) {
  .dropDownItem {
    font-size: 18px;
  }
}

@media all and (max-width: 1200px) {
  .dropDownMenuList {
    font-size: 18px !important;
  }
}
