.callsContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.callsUnansweredCheckbox {
  text-align: center;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  margin: 10px auto;
  font-style: italic;
}

.callsUnansweredCheckbox > input {
  cursor: pointer;
}

.callsUnansweredCheckbox > label {
  padding-left: 10px;
}

.callsButton,
.candidatesTestsAddBtn,
.studentTestsBtn {
  margin: 20px auto;
  display: flex;
  border: none;
  background: #ef711d;
  padding: 12px 18px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.callsButton:hover,
.candidatesTestsAddBtn:hover,
.studentTestsBtn:hover {
  background: #9fdd3a;
}

.callsTable,
.candidatesTestsTable,
.studentTestsTable {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.callsTableTr,
.candidatesTestsTableTr,
.studentTestsTableTr {
  border-bottom: 1px solid lightgray;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  padding: 15px;
}

.callsTableTr > th,
.candidatesTestsTableTr > th,
.studentTestsTableTr > th {
  font-weight: 700;
}

.callsTableTr > td,
.candidatesTestsTableTr > td,
.studentTestsTableTr > td {
  padding: 10px 0;
}

.callsIcons {
  display: flex;
  justify-content: space-evenly;
}

.callsIcon {
  cursor: pointer;
}

.callsIcon:hover {
  opacity: 80%;
}

/**/

.callsAddPopupCloseBtn {
}

/**   CSS for add call popup **/

.callsAddPopupContainer,
.applicantLogsEditMeetingPopupContainer,
.applicantNotesAddContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.callsAddPopupWrapper,
.applicantLogsEditMeetingPopupWrapper,
.applicantNotesAddWrapper {
  background-color: rgba(255, 255, 255, 0.9);
  width: 35%;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  box-shadow: 0 0 10px lightgray;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

.callsAddPopupCloseBtn {
  font-size: 25px;
  cursor: pointer;
  float: right;
}

.callsAddPopupCloseBtn:hover {
  color: #ef711d;
}

.callsAddPopupForm,
.applicantLogsEditMeetingPopupForm {
  display: flex;
  flex-direction: column;
}

.callsAddPopupForm > label {
  margin: 2.5px 0;
  text-align: center;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.callsAddPopupFormLabelInline {
  display: flex;
  width: 100%;
}

.callsAddPopupFormLabelInline > label {
  margin: 2.5px 0;
  text-align: center;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.callsAddPopupFormLabelInline > label > input,
.callsAddPopupFormLabelInline > label > select {
  width: 100%;
  height: 40px;
  text-align: center;
  padding: 10px;
}

.callsAddPopupForm > label > select,
.callsAddPopupForm > label > input {
  width: 100%;
  height: 40px;
  text-align: center;
}

.callsAddPopupForm > label > input,
.callsAddPopupForm > select,
.callsAddPopupForm > input {
  padding: 10px;
}

.callsAddPopupForm > label > input {
  padding: 10px;
  width: 100%;
}

.callsAddPopupBtn {
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  width: 30%;
  margin: 10px auto;
}

.callsAddPopupBtn:hover {
  background-color: #4a154d;
  color: white;
}

/**  END CSS for add call popup **/
