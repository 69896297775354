.applicationsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.applicationsFilterWrapper > p {
  color: #ef711d;
  font-family: "Varela", sans-serif;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
}

.applicationsFilterForm {
  display: flex;
  flex-direction: column;
  height: 350px;
  justify-content: space-between;
}

.applicationsFilterFormWrapper {
  column-count: 3;
}

.applicationsFilterFormCol1,
.applicationsFilterFormCol2,
.applicationsFilterFormCol3 {
  margin-bottom: 20px;
  display: block;
  width: 350px;
  height: 60px;
  padding: 10px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  border: 1px solid #8b8b8b;
  outline-color: #9fdd3a !important;
}

.applicationsFilterForm button,
.applicationsListWrapper button {
  display: flex;
  margin: auto;
  border: none;
  background: #ef711d;
  padding: 12px 18px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

.applicationsFilterForm button:hover,
.applicationsListWrapper button:hover {
  background: #9fdd3a;
}

.applicationsFilterFormReset {
  text-align: center;
  display: flex;
  margin: auto;
  border: 1px solid lightgray;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

/***/

.applicationsListWrapper {
  max-height: 600px;
  overflow: scroll;
}

.applicationsListWrapper,
.applicationsFilterWrapper {
  border: 1px solid lightgray;
  padding: 20px;
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.applicationsList {
  width: 100%;
  text-align: center;
}

.applicationsList > tr > th {
  font-family: "Varela", sans-serif;
  font-size: 20px;
  padding-bottom: 20px;
}

.applicationsListSingle > td {
  padding: 5px 0;
  font-family: "Varela", sans-serif;
  font-size: 15px;
}

.applicationsListSingle {
  border-bottom: 1px solid lightgray;
}

.applicationsListSingle:hover {
  opacity: 80%;
}

.applicationsListStars {
  color: gold;
}

.unseenApplication {
  background-color: rgba(211, 211, 211, 0.5);
}

.applicationsStatusColorCode {
  display: flex;
  width: 10%;
  justify-content: space-between;
}

.applicationsStatusColorCode > span {
  width: 25px;
  height: 25px;
  background-color: rgba(211, 211, 211, 0.5);
}

.applicationsStatusInfo {
  margin-top: 10px;
  margin-left: calc(15% / 2);
}

.applicationsStatusInfo > p {
  width: 90%;
}

.applicationsListPhoto,
.applicationsListNoPhoto {
  width: 25px;
  height: 25px;
  object-fit: contain;
  border-radius: 50%;
}

.applicationsListPhotoTd {
  position: relative;
}

.applicationsListPhoto {
  position: relative;
  transition: transform 0.3s ease;
}

.applicationsListPhoto:hover {
  transform: scale(10);
}

.applicationsFilterFormMultiSelectCol {
}
