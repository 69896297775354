.sidebarMiniContainer {
  background-color: #4a154d;
  z-index: 999;
  position: fixed;
  border: 1px solid white;
  border-radius: 5px;
  left: 0; //changed from right to left
  top: 2%; //changed from 10 to 2
  display: flex;
  flex-direction: column;
  padding: 15px;
  color: white;
  justify-content: space-between;
  //height: 45%;
  width: 380px;
}

.sidebarMiniContainerCollapsed {
  background-color: #4a154d;
  z-index: 999;
  position: fixed;
  border: 1px solid white;
  border-radius: 5px;
  left: 0; //changed from right to left
  top: 0%; //changed from 10 to 0
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: white;
  justify-content: space-between;
  height: fit-content;
  //width: 60px;
}

.sidebarShowIcon {
  z-index: 999;
  position: fixed;
  border-radius: 5px;
  left: 0; //changed from right to left
  top: 10%;
  padding: 5px;
  color: #ef711d;
  border: 1px solid white;
  height: fit-content;
}

.sidebarMiniContainer > h3 {
  text-align: center;
}

.sidebarMiniSocialIconsWrapper {
  display: flex;
  justify-content: space-evenly;
}

.sidebarMiniIcon:hover {
  transform: scale(1.5) !important;
}

.sidebarMiniIgIcon:hover {
  color: #bc318f !important;
}

.sidebarMiniYtIcon:hover {
  color: #ff0000 !important;
}

.sidebarMiniFbIcon:hover {
  color: #4267b2 !important;
}

.sidebarMiniIcon,
.sidebarMiniCalendarIcon {
  color: white;
  margin-top: 10px;
  cursor: pointer;
  font-size: 4vh !important;
}

.sidebarMiniCalendarIcon {
  margin-bottom: 10px;
}

.sidebarMiniEventsWrapper {
  padding: 10px 0;
  color: white;
  font-family: "Varela", sans-serif;
}

.sidebarMiniEvent {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  opacity: 80%;
  color: #9fdd3a;
}

.sidebarMiniEventTitle {
  overflow: hidden;
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebarMiniEvent:hover {
  opacity: 100%;
}

.sidebarMiniIconsCollapsed {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.sidebarMiniIconShown {
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebarDivider {
  border-top: 1px solid #bbb !important;
  width: 100%;
}

.sideBarShowHideIcon {
  cursor: pointer;
  margin: auto;
}

//// STYLE QUERIES

@media all and (max-width: 850px) {
  .sidebarMiniContainer,
  .sidebarMiniContainerCollapsed {
    display: none;
  }
}
