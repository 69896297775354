.schoolCalendarNotes {
  margin-top: 10px !important;
  display: flex;
  width: 30%;
  margin: auto;
  justify-content: space-between;
}

.schoolCalendarNotes > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.schoolCalendarNotes > div > span > span {
  width: 20px;
  height: 20px;
  background-color: rgba(211, 211, 211, 0.5);
  margin-right: 5px;
}

.schoolCalendarNotes > div > span > div {
  margin-right: 5px;
}

.schoolCalendarNotes > div > span {
  display: flex;
}

.schoolCalendarSwitches {
  display: flex;
  margin: 15px auto;
  width: 80%;
  justify-content: space-evenly;
}

.schoolCalendarPromptContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999;
}

.schoolCalendarPromptWrapper {
  background-color: white;
  width: 60%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px lightgray;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.schoolCalendarPromptPopupWrapper,
.meetingsPromptPopupWrapper {
  background-color: white;
  width: 20%;
  height: 280px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px lightgray;
  position: fixed;
  top: 50%;
  left: 50%;
  align-items: center;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.schoolCalendarEditPopupWrapper,
.meetingsEditPopupWrapper,
.meetingsViewPopupWrapper {
  background-color: white;
  width: 60%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px lightgray;
  position: fixed;
  top: 50%;
  left: 50%;
  align-items: center;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.schoolCalendarPromptPopupWrapper > button,
.meetingsPromptPopupWrapper > button {
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  width: 60%;
}

.schoolCalendarPromptPopupWrapper > button:hover,
.meetingsPromptPopupWrapper > button:hover {
  background-color: #4a154d;
  color: white;
}

.schoolCalendarPromptWrapper > p,
.schoolCalendarEditPopupWrapper > p,
.meetingsPromptPopupWrapper > p,
.meetingsEditPopupWrapper > p,
.meetingsViewPopupSpanWrapper > p {
  font-size: 23px;
  font-weight: 600;
}

.schoolCalendarPromptWrapper > form,
.schoolCalendarEditPopupWrapper > form,
.meetingsEditPopupWrapper > form {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.schoolCalendarPromptSpan {
  display: flex;
  height: 60px;
  margin-bottom: 20px;
}

.schoolCalendarPromptSpan > p {
  color: #ef711d;
  height: fit-content;
  margin-top: auto;
}

.schoolCalendarPromptSpan > input,
.schoolCalendarPromptSpan > select {
  display: block;
  width: 350px;
  height: 100%;
  padding: 10px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  border: 1px solid #8b8b8b;
  color: #8b8b8b;
  outline-color: #9fdd3a !important;
  text-align: center;
}

.calendarMultiSelect {
  margin-bottom: 20px;
  display: block;
  width: 350px;
  height: 60px;
  padding: 10px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  border: 1px solid #8b8b8b;
  color: #8b8b8b;
  outline-color: #9fdd3a !important;
  text-align: center;
}

.schoolCalendarPromptWrapper > form > span > input,
.schoolCalendarPromptWrapper > form > span > select,
.schoolCalendarPromptWrapper > form > span > label,
.schoolCalendarEditPopupWrapper > form > span > input,
.schoolCalendarEditPopupWrapper > form > span > select,
.schoolCalendarEditPopupWrapper > form > span > label,
.meetingsEditPopupWrapper > form > span > input,
.meetingsEditPopupWrapper > form > span > select,
.meetingsEditPopupWrapper > form > span > label,
.schoolCalendarEditPopupWrapperLabelInline > label,
.schoolCalendarEditPopupWrapperLabelInline > input {
  margin-bottom: 20px;
  display: block;
  width: 350px;
  height: 60px;
  padding: 10px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  border: 1px solid #8b8b8b;
  color: #8b8b8b;
  outline-color: #9fdd3a !important;
  text-align: center;
}

.schoolCalendarEditPopupWrapperLabelInline {
  height: 60px;
  display: flex;
  margin-bottom: 20px;
}

.schoolCalendarPromptWrapper > form > span > label,
.schoolCalendarEditPopupWrapper > form > span > label,
.schoolCalendarEditPopupWrapperLabelInline,
.meetingsEditPopupWrapper > form > span > label {
  cursor: pointer;
}

.schoolCalendarPromptBtns,
.meetingsPromptBtns {
  margin: auto;
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.schoolCalendarPromptBtns > button,
.meetingsPromptBtns > button {
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  width: 40%;
}

.schoolCalendarPromptBtns > button:hover,
.meetingsPromptBtns > button:hover {
  background-color: #4a154d;
  color: white;
}

.Kindergarten {
  background-color: #de7134;
  color: black;
  width: 100%;
  font-size: 16px;
  padding: 2px;
}

.Elementary {
  background-color: #4a164d;
  color: white;
  width: 100%;
  font-size: 16px;
  padding: 2px;
}

.Middle {
  background-color: #8dc341;
  color: black;
  width: 100%;
  font-size: 16px;
  padding: 2px;
}

.High {
  background-color: gray;
  color: white;
  width: 100%;
  font-size: 16px;
  padding: 2px;
}

.many {
  background-color: #1a252f;
  color: white;
  width: 100%;
  font-size: 16px;
  padding: 2px;
}

.schoolCalendarEventTitle {
  overflow: hidden !important;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendarFormImgPreview {
  width: 25px;
  height: 25px;
  object-fit: contain;
  border: 0.2px solid lightgray;
  z-index: 999;
}

.calendarFormImgPreview:hover {
  transform: scale(20);
  border: none;
}

.calendarFormImgPreviewLength {
  color: lightgray;
  font-size: 15px;
}

.calendarPhotoPreviewSpan {
  display: flex;
  width: fit-content;
  float: right;
  margin-top: auto;
  margin-bottom: auto;
}

//RESPONSIVE DESIGN STYLES

@media all and (max-width: 480px) {
  .schoolCalendarNotes {
    flex-direction: column;
  }
}

@media all and (max-width: 700px) {
  .schoolCalendarNotes {
    width: 80% !important;
  }
}

@media all and (max-width: 1100px) {
  //Change width/font-size of cycles
  .schoolCalendarNotes {
    width: 60%;
  }
  .schoolCalendarSwitches {
    flex-direction: column;
    align-items: center;
  }
}

@media all and (max-width: 1350px) {
  .schoolCalendarSwitches {
    width: 100%;
  }
}
